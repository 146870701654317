import React from "react";
import { Button } from "@mui/material";
import moment from "moment";
import { ReactComponent as TrendingUp } from "../../../../../Assets/icons/trending_up.svg";
import { ReactComponent as RecommendationCost } from "../../../../../Assets/icons/cudos-recommendation-cost.svg";
import { ReactComponent as TrendingDown } from "../../../../../Assets/icons/trending_down.svg";
import { ReactComponent as Arrow } from "../../../../../Assets/icons/arrow-down.svg";
import { ReactComponent as NoDataDoughnut } from "../../../../../Assets/images/NoDataDoughnut.svg";
import { numberWithCommasandDecimal } from "../../../../../Utils/commonUtils";
import { getNAObjectForCard } from "../cudosUtil";
import FusionChart from "../../../Common/fusionChart";

export const renderTrendPercentage = (percentage, differenceBetweenValues, parentClass) => (
  <div className={parentClass}>
    {differenceBetweenValues > 0 ? (
      <div className="trend-percentage-container-up">
        <TrendingUp />
        <span className="trend-percentage">{percentage?.toFixed(0)}%</span>
      </div>
    ) : (
      <div className="trend-percentage-container-down">
        <TrendingDown />
        <span className="trend-percentage">{percentage?.toFixed(0)}%</span>
      </div>
    )}
    <span className="difference-value">{numberWithCommasandDecimal(differenceBetweenValues)}</span>
  </div>
);

export const renderCardsData = (data, index) => (
  <div className={`${index === 2 ? "card last-card" : "card"}`}>
    <p className="month-year">{data.month}</p>
    <div className="flex gap-[30px] mt-[10px]">
      <div>
        <p className="cost-heading flex-grow">Cost</p>
        <p className="cost">
          {data.isNABlock ? "--" : numberWithCommasandDecimal(Number(data?.amount), 0)}
        </p>
        {data.isPercentageBoxVisible && !data.forecastValue ? (
          <div className="flex gap-[30px]">
            {renderTrendPercentage(data?.percentage, data?.differenceBetweenValues)}
          </div>
        ) : null}
      </div>
      {data?.forecastValue || (!data?.isPercentageBoxVisible && !data?.isNABlock) ? (
        <div>
          {data?.isForecastPresent ? (
            <>
              <p className="cost-heading">Forecast</p>
              <p className="cost">
                {!data?.forecastValue
                  ? "--"
                  : numberWithCommasandDecimal(Number(data.forecastValue), 0)}
              </p>
            </>
          ) : null}
          {data?.isPercentageBoxVisible ? (
            <div className="flex gap-[30px]">
              {renderTrendPercentage(data?.percentage, data?.differenceBetweenValues)}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  </div>
);

export const renderDetailCard = (data, heading, subHeading) => (
  <div className="flex space-around gap-[15px] items-end">
    <RecommendationCost />
    <div>
      <p className="text-[16px] font-[500] text-[#2B2B2B]">{heading}</p>
      <p className="text-[9px] font-[400] text-[#737373]">{subHeading}</p>
      <p className="cost">{numberWithCommasandDecimal(Number(data?.cost))}</p>
    </div>
    <div className="ml-[-7px]">
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.open(`/dashboard${data?.link}`, "_blank")}
        endIcon={<Arrow className="transform rotate-90" />}
      >
        View Details
      </Button>
    </div>
  </div>
);

export const getDownloadCardData = (cudosCardData) => {
  const data = new URLSearchParams(window.location.search);
  const forecastData =
    !!cudosCardData &&
    cudosCardData?.analyticsTrendDto?.list.map((individualOption) => ({
      month: moment(individualOption?.monthYear, "MMM-YYYY").format("MMM YYYY"),
      amount: individualOption?.currentMonthValue ? individualOption?.currentMonthValue : 0,
      percentage: individualOption?.percentageTrend ? individualOption?.percentageTrend : 0,
      differenceBetweenValues: individualOption?.differenceBetweenValues
        ? individualOption?.differenceBetweenValues
        : 0,
      forecastValue: individualOption?.forecastValue ? individualOption?.forecastValue : 0,
      isPercentageBoxVisible: individualOption?.isPercentageBoxVisible,
      isForecastPresent: individualOption?.isForecastPresent,
    }));

  for (let i = forecastData.length; i < 3; i += 1) {
    forecastData.unshift(
      getNAObjectForCard(
        moment(`${data.get("month")} ${data.get("year")}`, "MM YYYY").subtract(i, "months")
      )
    );
  }
  const forecastdataObj = {
    cost: cudosCardData?.recommendationSummary?.savings,
    link: cudosCardData?.recommendationSummary?.link,
  };
  forecastData.push(forecastdataObj);
  return forecastData;
};

const manipulateDataForGraph = (rows = [], ColumnNames = []) => {
  const serviceList = [];
  rows.forEach((item) => {
    const internalObj = {
      seriesName: item.rowName,
      seriesValues: ColumnNames.map(
        (internalRow) => item.columnValuesForRow?.[internalRow]?.cellValue
      ),
    };
    serviceList.push(internalObj);
  });
  const obj = {
    categories: ColumnNames,
    seriesList: serviceList,
  };
  return obj;
};

export const manipulateApiResponse = (resp, appendUnit) => {
  const {
    data: {
      data: { allSortedColumnLabels = [], columnWiseSum = [], tableRows = [] },
    },
  } = resp;
  const updatedResp = { ...resp };
  const tableStructure = {
    customTableDto: {
      tableRows,
      columnWiseSum,
      columnNames: allSortedColumnLabels,
    },
    lineChart: tableRows.length ? manipulateDataForGraph(tableRows, allSortedColumnLabels) : {},
  };
  if (appendUnit) {
    tableStructure.customTableDto.tableRows = tableRows.map((row) => {
      const rowCopy = row;
      rowCopy.columnValuesForRow.Unit = row.unit;
      return rowCopy;
    });
    tableStructure.customTableDto.columnNames = ["Unit", ...allSortedColumnLabels];
  }
  updatedResp.data.data = tableStructure;
  return updatedResp;
};

export const structureTrendArray = (data) => {
  const dataArray = Object.entries(data).map(([key, value]) => {
    let headerName;
    if (key === "totalCost") {
      headerName = "Total Cost of 3 months";
    } else if (key === "currentMonthCost") {
      headerName = "Cost - Current Month";
    } else {
      headerName = "Forecast - Current Month";
    }
    return {
      headerName,
      value: value?.currentMonthValue,
      trendPercentage: value?.percentageTrend,
      trendValue: value?.differenceBetweenValues,
    };
  });

  return dataArray;
};

export const getSelectedTab = (tab, tabArr = []) =>
  tabArr.filter((item) => item.label === tab)[0]?.value;

export const getCalenderType = (days = 0) => ({
  text: `Last ${days} days`,
  class: "!text-[9px] !text-[#B0B0B0] font-normal mr-[5px] mt-[1px]",
});

export const getDoughnutChart = (graphData = {}) =>
  graphData?.graphData?.data?.some((item) => item?.value !== 0) ? (
    <FusionChart graphData={graphData?.graphData} chartType={graphData?.type} />
  ) : (
    <div>
      <NoDataDoughnut />
    </div>
  );

export const formatValue = (value, formatter) => (value ? formatter(value) : "- -");
