import React, { Component } from "react";
import { redirectUser } from "../../../Utils/userUtils";
import { getLocalStorageUserData } from "../../../Utils/commonUtils";
import SomethingWentWrong from "./SomethingWentWrong";
import logError from "./Logger";
import "./style.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    console.info("Error check", JSON.stringify(error));
    if (
      error?.code === "MODULE_NOT_FOUND" ||
      error?.code === "CSS_CHUNK_LOAD_FAILED" ||
      error?.name === "ChunkLoadError"
    ) {
      window.location.reload(true);
      return {
        hasError: false,
      };
    }
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    const { currentUser } = this.getUserData();
    console.info("Error check", JSON.stringify(error));

    if (
      error?.code === "MODULE_NOT_FOUND" ||
      error?.code === "CSS_CHUNK_LOAD_FAILED" ||
      error?.name === "ChunkLoadError"
    ) {
      window.location.reload(true);
      return {
        hasError: false,
      };
    }
    this.setState({
      hasError: true,
    });

    logError({ error, errorInfo, currentUser });
    return null;
  }

  getUserData = () => {
    try {
      const localStorageItems = getLocalStorageUserData();
      const currentUser = localStorageItems?.currentUser;
      const roleType = currentUser?.type;
      return { redirecturl: redirectUser(roleType), currentUser };
    } catch (error) {
      delete localStorage.userData;
      return false;
    }
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    const { redirecturl } = this.getUserData();

    if (hasError) {
      return (
        <div className="page-not-found-wrapper errorPageNotFound">
          <SomethingWentWrong redirecturl={redirecturl} />
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
