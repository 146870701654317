import React from "react";
import { noop } from "../../../../../Utils/commonUtils";

function ToggleBtnGroup(props) {
  const { toggleBtnClick = noop(), active = "", buttonArray = [], className = "" } = props;

  const activeClass = "!bg-[#0A3CA2] text-[#fff] rounded-[16px]";
  const inActiveClass = "!bg-[#EEF1F9] text-[#0A3CA2]";
  const btnClass = "text-[12px] font-normal px-[8px] py-[2px]";
  return (
    <div
      className={`${className} bg-[#EEF1F9] p-[2px] w-[fit-content] rounded-[16px] h-[22px] flex`}
    >
      {buttonArray.map((item) => (
        <button
          type="button"
          className={`${btnClass} ${active === item.value ? activeClass : inActiveClass}`}
          id="active"
          onClick={() => toggleBtnClick(item.value)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}

export default ToggleBtnGroup;
