import moment from "moment";
import React from "react";
import { getCurrencySymbol } from "../../../../Utils/commonUtils";

export const manipulateAccordionTableData = (data = []) => {
  let tableHeaders = [];
  let tableFooter = {};
  if (data.length > 0) {
    const columnHeaders = Object.keys(data[0]?.costDates).map((item) => {
      tableFooter[item] = 0;
      return {
        label: item,
        value: item,
        suffix: "K",
        prefix: `${getCurrencySymbol()}`,
      };
    });
    tableHeaders = [...[{ label: "Services", value: "groupValue" }, ...columnHeaders]];
    data?.forEach((row) => {
      if (row?.groupValue === "Total") {
        tableFooter = { ...row?.costDates, groupValue: row?.groupValue };
      }
    });
  }
  return {
    tableData: data?.filter((row) => row?.groupValue !== "Total"),
    tableHeaders,
    tableFooter,
  };
};

/**
 * Returns a formatted value with thousands representation.
 * If the input value is a number greater than 1000, it returns the value divided by 1000
 * with two decimal places and an optional suffix (default: 'K').
 * If the input value is a number less than or equal to 1000, it returns the value with two decimal places.
 * If the input value is not a number, it returns the input value as is.
 *
 * @param {number|string} val - The input value to format (default: 0).
 * @param {string} suffix - The optional suffix for values greater than 1000 (default: '').
 * @returns {string|number} The formatted value.
 */
export const getThousandsValue = (val = 0, suffix = "") => {
  // Check if the value is a number or can be coerced into a number
  if (typeof val === "number" || +val) {
    const numericVal = +val;

    // If the value is greater than 1000, format it with thousands representation
    if (numericVal && numericVal > 1000) {
      return `${(numericVal / 1000)?.toFixed(2)}${suffix || "K"}`;
    }

    // If the value is less than or equal to 1000, format it with two decimal places
    return numericVal?.toFixed(2);
  }

  // If the value is not a number, return it as is
  return val;
};

export const getNABlock = (id) => (
  <>
    <div className="download_card_values">
      <h3>
        <div className="leftValueContainer">
          <span id={id ? `monthCost-${id}` : ""}>N/A</span>
        </div>
      </h3>
    </div>
    <div className="download_card_footer">
      <div className="increasedBy">
        <span>Increased/Decreased by</span>
        <div className="cardValue ">
          <b> N/A </b>
        </div>
      </div>
      <div className="s3cardFrom">
        <span>From</span>
        <div className="cardValue">
          <b> N/A </b>
        </div>
      </div>
    </div>
  </>
);

export const getNAObjectForCard = (monthYear) => ({
  month: moment(monthYear).format("MMM YYYY"),
  isNABlock: true,
  disableExport: true,
});

export const getAdwisorContainer = (key, content, link) => (
  <div className="ckAdwisor-widget-container" key={key}>
    <div className="widgetInner">
      <div className="widget-heading mb-3.5">
        {/* Trusted by our clients */}
        <h1 className="!capitalize">Recommendation</h1>
      </div>
      {content}
      <div className="advisorBtnCont">
        <a id="viewRecommendation" href={`/dashboard${link}`} target="_blank" rel="noreferrer">
          View Details
        </a>
      </div>
    </div>
  </div>
);
