import axios from "axios";
import moment from "moment";
import { getCurrencySymbol } from "../../Utils/commonUtils";

export const generateString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// export const logout = async () => {
//   localStorage.removeItem("userData");
//   window.location.reload();
// };

export const serialize = (obj) => {
  const str = [];
  Object.keys(obj).forEach((p) => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  });
  return str.join("&");
};

export const isAPICanceled = (e) => {
  if (axios.isCancel(e)) {
    return false;
  }
  return null;
};

export const NumberFormatting = (number) => {
  if ((number / 1000).toFixed(2) === "-0.00") {
    return ((number * -1) / 1000)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (number / 1000 < 1) {
    if ((number / 1000).toFixed(2) === "0.00") {
      return (number / 1000)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (number / 1000)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (number / 1000)
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const downloadCsv = (response) => {
  document.createElement("a");
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", response.headers.filename);
  document.body.appendChild(link);
  link.click();
};

const numberCheck = (x) => {
  if (x < 0 && x > -1) {
    const withoutNegative = x * -1;
    // return `-${withoutNegative.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    return `${withoutNegative.toFixed(2) === 0 ? "" : "-"}${withoutNegative.toFixed(2)}`;
  }
  if (x < -1) {
    const withoutNegative = x * -1;
    // return `-${withoutNegative.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    return `-${withoutNegative.toFixed(0)}`;
  }
  if (x >= 0 && x < 1) {
    return `${x.toFixed(2)}`;
  }
  // return `${x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  return `${x.toFixed(0)}`;
};

export const numberWithCommasandDecimal = (x = 0, type = "number") => {
  if (typeof x === "number") {
    switch (type) {
      case "number":
        return numberCheck(x);

      case "percentage":
        if (x < 0 && x > -1) {
          const withoutNegative = x * -1;
          return `${withoutNegative.toFixed(1) === 0 ? "" : "-"}${withoutNegative
            .toFixed(1)
            .toString()}%`;
        }
        if (x < -1) {
          const withoutNegative = x * -1;
          return `-${withoutNegative.toFixed(1).toString()}%`;
        }
        if (x >= 0 && x < 1) {
          return `${x.toFixed(1).toString()}%`;
        }
        return `${x.toFixed(1).toString()}%`;
      default:
        return null;
    }
  } else {
    return x;
  }
};

export const numberWithCommasandDecimalAndDollar = (x = 0, type = "number") => {
  if (typeof x === "number") {
    switch (type) {
      case "dollar":
        if (x < 0 && x > -1) {
          const withoutNegative = x * -1;
          if (x.toFixed(2) === "-0.00") {
            return `${getCurrencySymbol()}${withoutNegative
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
          return `-${getCurrencySymbol()}${withoutNegative
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

          // return `-${withoutNegative.toFixed(2)}`;
        }
        if (x < -1) {
          const withoutNegative = x * -1;
          return `-${getCurrencySymbol()}${withoutNegative
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          // return `-${withoutNegative.toFixed(0)}`;
        }
        if (x >= 0 && x < 1) {
          return `${getCurrencySymbol()}${x.toFixed(2)}`;
        }
        return `${getCurrencySymbol()}${x
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      // return `${x.toFixed(0)}`;

      case "number":
        return numberWithCommasandDecimal(x, type);

      case "percentage":
        return numberWithCommasandDecimal(x, type);
      default:
        return x;
    }
  } else {
    return x;
  }
};

export const numberWithCommaDecimal = (number = 0) => {
  if (typeof number === "number") {
    return `${getCurrencySymbol()}${Number(number)
      ?.toFixed(0)
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return number;
};

export const regEx = /\B(?=(\d{3})+(?!\d))/g;

export const convertDateintoFormat = (date) => moment(date).format("DD-MMM-YY");

export const ConvertToBoolean = (data) => {
  if (data === "true") {
    return true;
  }
  if (data === "false") {
    return false;
  }
  if (data === "null") {
    return false;
  }
  return data;
};

export const numberWithCommasandDecimalAndDollarWithoutDecimal = (x = 0, type = "number") => {
  if (typeof x === "number") {
    switch (type) {
      case "number":
        if (x < 0 && x > -1) {
          const withoutNegative = x * -1;
          return `${
            withoutNegative.toFixed(0) === 0 ? "" : "-"
          }${getCurrencySymbol()}${withoutNegative
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          // return `-${withoutNegative.toFixed(2)}`;
        }
        if (x < -1) {
          const withoutNegative = x * -1;
          return `-${getCurrencySymbol()}${withoutNegative
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          // return `-${withoutNegative.toFixed(0)}`;
        }
        if (x >= 0 && x < 1) {
          return `${getCurrencySymbol()}${x.toFixed(0)}`;
        }
        return `${getCurrencySymbol()}${x
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      // return `${x.toFixed(0)}`;
      default:
        return null;
    }
  } else {
    return x;
  }
};

export const dataForProfitability = (data, bracketData) => {
  if (typeof data === "number") {
    return `${numberWithCommasandDecimalAndDollarWithoutDecimal(
      data,
      "number"
    )} (${numberWithCommasandDecimal(bracketData, "percentage")})`;
  }
  return data;
};

export const dataRenderer = (x = "") => {
  switch (typeof x) {
    case "number":
      if (x === "") {
        return "--";
      }
      return x;
    case "string":
      if (x === "") {
        return "--";
      }
      break;
    default:
      return x;
  }
  return x;
};

export const InventoryTable = (x = 0, type = "number") => {
  if (typeof x === "number") {
    switch (type) {
      case "number":
        if (x < 0 && x > -1) {
          const withoutNegative = x * -1;
          return `-${withoutNegative
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        if (x < 1 && x > 0.0) {
          // const withoutNegative = x * -1;
          return `${x
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        if (x < -1) {
          const withoutNegative = x * -1;
          return `-${withoutNegative
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        return `${x
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

      case "percentage":
        if (x < 0 && x > -1) {
          const withoutNegative = x * -1;
          return `-${withoutNegative.toFixed(1).toString()}%`;
        }
        if (x < -1) {
          const withoutNegative = x * -1;
          return `-${withoutNegative.toFixed(1).toString()}%`;
        }
        if (x >= 0 && x < 1) {
          return `${x.toFixed(1).toString()}%`;
        }
        return `${x.toFixed(1).toString()}%`;
      default:
        return x;
    }
  } else {
    return x;
  }
};

const strinDateCondition = (ascending, sortBy, a, b) => {
  if (sortBy.includes("Date") || sortBy.includes("date")) {
    if (ascending) {
      return moment(a[sortBy]) > moment(b[sortBy]) ? 1 : -1;
    }
    return moment(a[sortBy]) > moment(b[sortBy]) ? -1 : 1;
  }
  if (typeof a?.[sortBy] === "string") {
    if (ascending) {
      return b?.[sortBy]?.toString()?.localeCompare(a?.[sortBy]?.toString(), undefined, {
        numeric: true,
        sensitivity: "base",
      });
    }
    return a?.[sortBy]?.toString().localeCompare(b?.[sortBy]?.toString(), undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }
  return null;
};

export const sortedData = (ascending, data, sortBy, toggle = false) => {
  const updatedData = [...data];
  updatedData.sort((a, b) => {
    strinDateCondition(ascending, sortBy, a, b);
    if (ascending) {
      return (
        a[sortBy] - b[sortBy] ||
        (toggle
          ? a[sortBy].costPercent - b[sortBy].costPercent
          : a[sortBy].cost - b[sortBy].cost) ||
        a[sortBy].costPercent - b[sortBy].costPercent ||
        (!!a?.[sortBy]?.cost &&
          !!a?.[sortBy]?.cost?.toString()?.includes("%") &&
          a[sortBy].cost.replace("%", "") - b[sortBy].cost.replace("%", ""))
      );
    }
    return (
      b[sortBy] - a[sortBy] ||
      (toggle ? b[sortBy].costPercent - a[sortBy].costPercent : b[sortBy].cost - a[sortBy].cost) ||
      b[sortBy].costPercent - a[sortBy].costPercent ||
      (!!b?.[sortBy]?.cost &&
        !!b?.[sortBy]?.cost?.toString()?.includes("%") &&
        b[sortBy].cost.replace("%", "") - a[sortBy].cost.replace("%", ""))
    );
  });
  return updatedData;
};

export const decimalNumber = (key, x = 0) => {
  const numZero = 0;
  return key === "hour"
    ? `${x
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` || x
    : `${
        x.toFixed(2) === 0
          ? numZero.toFixed(2)
          : x
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }`;
};

export const filterObjStructure = (item, internalItem) => {
  const ObjStructure = {
    name: item.name,
    includeOnly: item.includeOnly,
    key: item.key,
    type: item.type,
    value: item.values.filter((obj) => internalItem.value.indexOf(obj) === -1),
  };
  return ObjStructure;
};
export const getDropDownData = (
  filterName = "",
  values = [],
  selectedValues = [],
  includeAll = false,
  includeOnly = "Include Only"
) => ({
  includeOnly,
  includeAll,
  key: filterName,
  name: filterName,
  type: "string",
  value: selectedValues,
  values,
});

export const applyStringFilterIndividual = (data, filters, filtersCopy) => {
  const NewFIlters = [];
  filtersCopy.map((item) =>
    filters.map((internalItem) => {
      if (item.name === internalItem.name && internalItem.includeOnly === "Exclude Only") {
        const newData = filterObjStructure(item, internalItem);
        NewFIlters.push(newData);
      } else if (item.name === internalItem.name) {
        NewFIlters.push(internalItem);
      }
      return null;
    })
  );
  const newData = [];
  data.map((singleRow) => {
    const filtersList = NewFIlters.map((singleFilter) =>
      singleFilter.value.includes(singleRow[singleFilter.key])
    );
    if (filtersList.filter((item) => item === true).length === filtersList.length) {
      newData.push(singleRow);
    }
    return null;
  });
  return newData;
};

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const applyDatefiltersSingle = (internalItem, reducedData) => {
  const newData = [];
  reducedData.forEach((item) => {
    if (
      moment(item[internalItem.name])
        .startOf("day")
        .isBetween(
          moment(internalItem.selectedDates[0]).startOf("day"),
          moment(internalItem.selectedDates[1]).startOf("day"),
          "undefined",
          "[]"
        )
    ) {
      newData.push(item);
    }
  });
  return newData;
};

export const applyDateFilter = (newFilterDataObj, filters) => {
  const dateFilters = filters.filter((item) => item.type === "dates");
  const data = [...newFilterDataObj.tableData];
  let reducedData = [...data];

  dateFilters.forEach((internalItem) => {
    const dataNew = applyDatefiltersSingle(internalItem, reducedData);
    reducedData = dataNew;
  });
  return reducedData;
};

export const compareDates = (dateTimeA, dateTimeB) => {
  const momentA = moment(dateTimeA).format("YYYY-MM");
  const momentB = moment(dateTimeB).format("YYYY-MM");
  if (momentA >= momentB) return true;
  if (momentA < momentB) return false;
  return false;
};

export const differenceInDates = (d1, d2) => {
  const date1 = new Date(d1);
  let date2;
  if (d2) date2 = new Date(d2);
  else date2 = new Date();

  const DIFFERENCE_IN_TIME = date1.getTime() - date2.getTime();

  const DIFFERENCE_IN_DAYS = DIFFERENCE_IN_TIME / (1000 * 3600 * 24);
  return DIFFERENCE_IN_DAYS;
};

export const returnServiceName = (serviceName) => {
  switch (serviceName) {
    case "EC2":
      return "EC2";
    case "RDS":
      return "RDS";
    case "ElastiCache":
      return "ELASTI_CACHE";
    case "OpenSearch":
      return "OPENSEARCH";
    case "Redshift":
      return "REDSHIFT";
    default:
      return serviceName;
  }
};

export const isEmailValid = (email) => {
  const emailRegext =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegext.test(email);
};

export const hasDuplicates = (array) => new Set(array).size !== array.length;

export const escapeRegExpMatch = (s = "") => s.replace(/[-\\/\\^$*+?.()|[\]{}]/g, "\\$&");

export const isExactMatch = (str = "", match = "") =>
  new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str) || str.includes(match);
export const maxValueFromTable = (data, extraCols = []) => {
  let max = Number.NEGATIVE_INFINITY;

  data.map((row) => {
    Object.keys(row).map((col) => {
      if (!extraCols.includes(col)) {
        if (max < +row[col]) max = +row[col];
      }
      return null;
    });
    return null;
  });
  return max;
};

export const getParams = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key) || "";
};

export const getLocalStorageItem = (keyName) => JSON.parse(localStorage.getItem(keyName));

export const setLocalStroageItem = (keyName, objKey, objValue) => {
  let preObj = JSON.parse(localStorage.getItem(keyName));
  if (preObj) {
    preObj[objKey] = objValue;
  } else {
    preObj = { [objKey]: objValue };
  }
  localStorage.setItem(keyName, JSON.stringify(preObj));
};

export const getQueryParams = (queryParams) => {
  const paramObj = {};
  if (queryParams && queryParams !== "") {
    const queryParamsArr = queryParams.substr(1).split("&");
    queryParamsArr.forEach((params) => {
      const index = params.indexOf("=");
      const key = params.slice(0, index);
      const value = params.slice(index + 1);
      paramObj[key] = value;
    });
  }
  return paramObj;
};
