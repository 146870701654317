import React from "react";
import { ReactComponent as SearchIcon } from "../../../../Assets/icons/ic-search.svg";
import { ReactComponent as Cross } from "../../../../Assets/icons/cross-icon.svg";
import "../style.scss";

function Search({
  searchTerm = "",
  placeholder,
  onChangeHandler,
  styleSearch = "",
  className = "",
  overridePlaceholder = false,
  searchIconPostion = "",
  searchBarClass = "",
  searchResetEnabled = false,
}) {
  const placeHolder = overridePlaceholder ? placeholder : `Search ${placeholder}`;

  const handleKeyDown = (event) => {
    // handle enter key so that form should not be submitted
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <div
      className={`search-bar flex items-center justify-between border mx-4 mt-0 py-1.5 rounded text-[10px] text-[#939393] px-2.5 border-[#DDDFE8] border-solid bg-white ${styleSearch} ${searchBarClass} `}
    >
      {searchIconPostion !== "" && <SearchIcon color="#7C7C7C" size={11} />}
      <input
        value={searchTerm}
        onChange={onChangeHandler}
        className={`prompt w-full border-none mb-[0px] ${className}`}
        type="text"
        placeholder={placeHolder}
        onKeyDown={handleKeyDown}
      />
      {searchIconPostion === "" && <SearchIcon color="#7C7C7C" size={14} />}
      {searchResetEnabled && searchTerm.length ? (
        <Cross className="ck-search-cross" aria-hidden onClick={() => onChangeHandler("")} />
      ) : null}
    </div>
  );
}

export default Search;
