/* eslint-disable import/no-cycle */
// This file is used to dynamically lazy load all the private components under dashboard and map the same component with KEY provided in billdesk/dashboard/all/v4 API
// To dev test a component that isn't being pushed in the API yet, refer to src/utils/howToAddNewRoutes.md

import { lazy } from "react";
import CreateTemplate from "../Components/Dashboard/CreateTemplate";
import EKS from "../Components/CK-Lens/Dashboard/Cudos/EKS";

const RIOPSOnboard = lazy(() =>
  import("../Components/CK-Auto/potentialSavingDashboard/RIOPSOnboard")
);

const potentialSavingDetails = lazy(() => import("../Components/CK-Auto/potentialSavingDetails"));

const UserManagement = lazy(() => import("../Components/Dashboard/UserManagement"));
// const CustomerManagement = lazy(() => import("../Components/Dashboard/CustomerManagement"));
const Customers = lazy(() => import("../Components/Dashboard/CustomerManagement/index"));
const CustomersBilldesk = lazy(() => import("../Components/Billdesk/Customers/index"));
const PayerManagement = lazy(() => import("../Components/Dashboard/PayerManagement"));
const CreateUser = lazy(() => import("../Components/Dashboard/UserManagement/CreateUser"));
const CreatePartner = lazy(() => import("../Components/Dashboard/PartnerManagement/CreatePartner"));
const PartnerManagement = lazy(() => import("../Components/Dashboard/PartnerManagement"));

const CreateCustomer = lazy(() =>
  import("../Components/Dashboard/CustomerManagement/CreateCustomer")
);
const Customer = lazy(() =>
  import("../Components/Dashboard/CustomerManagement/CreateCustomer/customer")
);

const CreatePayer = lazy(() => import("../Components/Dashboard/PayerManagement/CreatePayer"));
const InvoiceSettings = lazy(() => import("../Components/Dashboard/Invoice/Settings"));
const InvoiceListing = lazy(() => import("../Components/Dashboard/Invoice/Templates"));
const instances = lazy(() => import("../Components/CK-Lens/Dashboard/Recommendations/instances"));
const Cloudfront = lazy(() => import("../Components/CK-Lens/Dashboard/Recommendations/cloudfront"));
const EBS = lazy(() => import("../Components/CK-Lens/Dashboard/Recommendations/ebs"));
const Ebssnapshot = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Recommendations/ebs-snapshot")
);
const IdleNetwork = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Recommendations/idle-Resources")
);
const CLB = lazy(() => import("../Components/CK-Lens/Dashboard/Recommendations/clb"));
const UPGRADE_VERSION = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Recommendations/upgradeVersion")
);
const CostDeviationCreateDaily = lazy(() =>
  import("../Components/CK-Lens/Dashboard/NotificationsV2/BudgetAlert/costDeviation/createdaily")
);
const AwsResellerRegistration = lazy(() =>
  import("../Components/CK-Auto/potentialSavingDashboard/AwsResellerRegistration")
);
const VirtualReservationCart = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Virtual-reservation")
);
const ViCart = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Virtual-reservation/components/cart/cart")
);
const LegacyLensAccountOnboarding = lazy(() => import("../Components/Legacy-Lens"));
// const DiscountTemplate = lazy(() => import("../Components/Dashboard/DiscountTemplate"));
// const CreateDiscountTemplate = lazy(() =>
//   import("../Components/Dashboard/Template/Configure-template")
// );
const ConfigDisctounts = lazy(() => import("../Components/Dashboard/ConfigDiscounts"));
const UpdatePlan = lazy(() => import("../Components/Dashboard/PlanSelection/UpdatePlan"));
const PlanSelection = lazy(() => import("../Components/Dashboard/PlanSelection"));
const Invoice = lazy(() => import("../Components/Dashboard/Invoice"));
const InvoiceGeneration = lazy(() => import("../Components/Dashboard/Invoice/generation"));
const DashboardControlGrid = lazy(() => import("../Components/Dashboard/DashboardControlGrid"));
const ModuleControlGrid = lazy(() => import("../Components/Dashboard/ModuleControlGrid"));
const BillingSummary = lazy(() => import("../Components/CK-Lens/Dashboard/BillingSummary"));
const HourlyDashboard = lazy(() => import("../Components/CK-Lens/Dashboard/HourlyDashboard"));

const BillingConsole = lazy(() => import("../Components/CK-Lens/Dashboard/BillingConsole"));
const TagReport = lazy(() => import("../Components/CK-Lens/Dashboard/TagReport"));

const AccountWise = lazy(() => import("../Components/CK-Lens/Dashboard/AccountWise_Breakup"));
const PartnerSettings = lazy(() => import("../Components/Dashboard/PartnerSettings"));
const CostExplorer = lazy(() => import("../Components/CK-Lens/Dashboard/CostExplorer"));
const InstanceCost = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostBreakup/Instance Cost")
);
const ServerLess = lazy(() => import("../Components/CK-Lens/Dashboard/CostBreakup/ServerLess"));
const CostBreakup = lazy(() => import("../Components/CK-Lens/Dashboard/CostBreakup"));
const Containers = lazy(() => import("../Components/CK-Lens/Dashboard/CostBreakup/Containers"));
const CudosDatabase = lazy(() => import("../Components/CK-Lens/Dashboard/Cudos/Database"));
const Database = lazy(() => import("../Components/CK-Lens/Dashboard/CostBreakup/Database"));
const Networking = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostBreakup/Networking&ContentDelivery")
);
const MigrationAndTransfer = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostBreakup/MigrationAndTransfer")
);
const Security = lazy(() => import("../Components/CK-Lens/Dashboard/CostBreakup/Security"));
const DataTransfer = lazy(() => import("../Components/CK-Lens/Dashboard/CostBreakup/DataTransfer"));
const Analytics = lazy(() => import("../Components/CK-Lens/Dashboard/CostBreakup/Analytics"));
const ManagementAndGovernance = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostBreakup/Management&Grovernance")
);
const MediaServices = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostBreakup/MediaServices")
);
const Utilization = lazy(() => import("../Components/CK-Lens/Dashboard/Reservations/Utilization"));
const ReservedInstances = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Reservations/ReservedInstances")
);
const Recommendations = lazy(() => import("../Components/CK-Lens/Dashboard/Recommendations"));
const Integrations = lazy(() =>
  import("../Components/CK-Lens/Dashboard/NotificationsV2/Integrations")
);
const BudgetAlert = lazy(() =>
  import("../Components/CK-Lens/Dashboard/NotificationsV2/BudgetAlert")
);
const Ricoverage = lazy(() => import("../Components/CK-Lens/Dashboard/RiCoverage"));
const AWSConsole = lazy(() => import("../Components/CK-Lens/Dashboard/AdminPages/AWSConsole"));

const CostDeviationCreateMonthly = lazy(() =>
  import("../Components/CK-Lens/Dashboard/NotificationsV2/BudgetAlert/costDeviation/create")
);
// const OndemandTemplates = lazy(() =>
//   import("../Components/Dashboard/CustomerManagement/CreateCustomer/onDemandTemplate")
// );
const S3 = lazy(() => import("../Components/CK-Lens/Dashboard/Recommendations/s3"));
const MAV = lazy(() => import("../Components/Dashboard/MAV_Preferences"));
const MPV = lazy(() => import("../Components/Dashboard/MPV"));
const Password = lazy(() => import("../Components/Dashboard/Password"));

const Tags = lazy(() => import("../Components/Dashboard/Tags"));
const OnDemandDiscount = lazy(() => import("../Components/Dashboard/Template/OnDemandDiscount"));
// const CloneDiscount = lazy(() => import("../Components/Dashboard/Template/CloneDiscount"));
const CudosS3 = lazy(() => import("../Components/CK-Lens/Dashboard/Cudos/S3"));
const CudosDataTransfer = lazy(() => import("../Components/CK-Lens/Dashboard/Cudos/DataTransfer"));
const SavingDelivered = lazy(() => import("../Components/CK-Auto/SavingsDelivered"));
const AddAWSAccount = lazy(() => import("../Components/CK-Auto/addAwsAccount"));
const PotentialSavingsDashboard = lazy(() =>
  import("../Components/CK-Auto/potentialSavingDashboard")
);
const PotentialSavingDetails = lazy(() => import("../Components/CK-Auto/potentialSavingDetails"));
const VirtualReservations = lazy(() => import("../Components/Dashboard/VirtualReservations"));
const MonthlyReports = lazy(() => import("../Components/Finops/Monthly-Reports"));
const WarReports = lazy(() => import("../Components/Finops/War-Reports"));
const ConsolidatedVRI = lazy(() => import("../Components/Finops/Consolidated-VRI"));
const FinopsRecommendations = lazy(() => import("../Components/Finops/Recommendations"));
const CustomerHealthCheck = lazy(() => import("../Components/Dashboard/CustomerHealthCheck"));
const MeteringRecords = lazy(() => import("../Components/Dashboard/MeteringRecords"));
const ConfigureFlatDiscount = lazy(() =>
  import("../Components/Dashboard/CustomerManagement/CreateCustomer/ConfigureFlatDiscount")
);
const EdpDiscounts = lazy(() => import("../Components/Dashboard/EdpDiscounts"));
const DiscountTemplate = lazy(() =>
  import("../Components/Dashboard/CustomerManagement/CreateCustomer/DiscountTemplate")
);
const CustomerInvoiceRecord = lazy(() =>
  import("../Components/Dashboard/CustomerInvoiceRecord/index")
);
const ResourceWise = lazy(() =>
  import("../Components/CK-Lens/Dashboard/ResourceGroupWise_Breakup")
);
const LoggingAndMonitoring = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostBreakup/LoggingAndMonitoring")
);
const ConfigureChargesAndRefunds = lazy(() =>
  import(
    "../Components/Dashboard/CustomerManagement/CreateCustomer/ChargesAndRefundsList/ConfigureChargesAndRefunds"
  )
);
const ConfigureCredits = lazy(() =>
  import("../Components/Dashboard/CustomerManagement/CreateCustomer/Credits/config/confgureCredits")
);
const ReservationDiscount = lazy(() => import("../Components/Dashboard/ReservationDiscount"));
const CloneDiscount = lazy(() => import("../Components/Dashboard/Template/CloneDiscount"));
const AzureOnboarding = lazy(() => import("../Components/CK-Azure/AzureOnboarding/index"));
const AzureManualOnboarding = lazy(() =>
  import("../Components/CK-Azure/AzureOnboarding/manualOnboarding/index")
);
const OnboardingSummary = lazy(() =>
  import("../Components/CK-Azure/AzureOnboarding/OnboardingSummary")
);
const Quotegenerator = lazy(() => import("../Components/Quote-generator"));
const ExpiryDetailed = lazy(() =>
  import("../Components/CK-Lens/Dashboard/NotificationsV2/BudgetAlert/Expiry/ExpiryDetailed")
);
const SummaryReportDetailed = lazy(() =>
  import(
    "../Components/CK-Lens/Dashboard/NotificationsV2/BudgetAlert/SummaryReport/SummaryReportDetailed"
  )
);
const CloudfrontForm = lazy(() =>
  import("../Components/Dashboard/CustomerManagement/CreateCustomer/Cloudfront")
);
const SavedReports = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostExplorer/SavedReports")
);

const CostUsageReport = lazy(() => import("../Components/CK-Lens/Dashboard/CostUsageReport"));

const CreateCostUsageReport = lazy(() =>
  import("../Components/CK-Lens/Dashboard/CostUsageReport/Components/CostUsageReportCreate")
);

const RiInventory = lazy(() =>
  import("../Components/CK-Analytics/Dashboard/Commitments/Inventory")
);
const CreatePermissions = lazy(() => import("../Components/Dashboard/RBAC/CreatePermissions"));
const PermissionListing = lazy(() => import("../Components/Dashboard/RBAC/Listing"));

const CreateTags = lazy(() => import("../Components/Dashboard/RBAC/CreateTags"));
const TagsListing = lazy(() => import("../Components/Dashboard/RBAC/TagsListing"));
const RIMarketplace = lazy(() => import("../Components/CK-Analytics/Dashboard/RiMarketPlace"));
const ConfigureAdditionalDiscount = lazy(() =>
  import("../Components/Billdesk/ExtraDiscount/Components/create")
);
const VriDeficit = lazy(() => import("../Components/CK-Analytics/Dashboard/Utilities/TtnRiVsVri"));
const HourlySavedReports = lazy(() =>
  import("../Components/CK-Lens/Dashboard/HourlyDashboard/SavedReports")
);

// For VRI Lens
const VRCustomer = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Virtual-reservation-customer/index")
);
const ViCartCustomer = lazy(() =>
  import("../Components/CK-Lens/Dashboard/Virtual-reservation-customer/components/cart/cart")
);
// Analytics Dashboards Starts Here
const CoverageServiceWise = lazy(() =>
  import("../Components/CK-Analytics/Dashboard/Commitments/Coverage/ServiceWise")
);
const CoveragePayerWise = lazy(() =>
  import("../Components/CK-Analytics/Dashboard/Commitments/Coverage/PayerWise")
);
const UnusedInventoryServiceWise = lazy(() =>
  import("../Components/CK-Analytics/Dashboard/Commitments/UnusedInventory/ServiceWise")
);
const UnusedInventoryPayerWise = lazy(() =>
  import("../Components/CK-Analytics/Dashboard/Commitments/UnusedInventory/PayerWise")
);
// Analytics Dashboards Ends Here
const RevenueTracker = lazy(() => import("../Components/CK-Analytics/Dashboard/Revenue/Tracker"));
const RevenueForecast = lazy(() => import("../Components/CK-Analytics/Dashboard/Revenue/Forecast"));

export const componentMapper = {
  BILLING_SUMMARY: BillingSummary,
  COMPUTE: HourlyDashboard,
  BILLING_CONSOLE: BillingConsole,
  PARTNER_SETTINGS: PartnerSettings,
  COST_EXPLORER: CostExplorer,
  COST_EXPLORER_SAVED_REPORTS: SavedReports,
  INVOICE: Invoice,
  GENERATE_INVOICES: InvoiceGeneration,
  DASHBOARD_CONTROL_GRID: DashboardControlGrid,
  MODULE_CONTROL_GRID: ModuleControlGrid,
  ACCOUNT_WISE_BREAKUP: AccountWise,
  INSTANCE_COST: InstanceCost,
  SERVERLESS: ServerLess,
  CONTAINERS: Containers,
  DATABASE: CudosDatabase,
  MANAGED_DATABASE: Database,
  NETWORK_CONTENT_DELIVERY: Networking,
  MIGRATION_TRANSFER: MigrationAndTransfer,
  SECURITY: Security,
  DATA_TRANSFER: CudosDataTransfer,
  EKS_DATABASE: EKS,
  ANALYTICS: Analytics,
  CLOUDWATCH: ManagementAndGovernance,
  MEDIA_SERVICES: MediaServices,
  UTILIZATION: Utilization,
  RECOMMENDATIONS: Recommendations,
  CUSTOMER_WISE_RECOMMENDATION: Recommendations,
  NOTIFICATION_GROUP: Integrations,
  BUDGET_ALERTS: BudgetAlert,
  COVERAGE: Ricoverage,
  AWS_CONSOLE_VS_ACTUAL_COST: AWSConsole,
  SIMPLE_STORAGE_SERVICE: CudosS3,
  MAV,
  PASSWORD: Password,
  TAGS: Tags,
  ON_DEMAND_DISCOUNTS_CUSTOMERS: OnDemandDiscount,
  DATA_TRANSFER_OLD: DataTransfer,
  SAVINGS_DELIVERED: SavingDelivered,
  ADD_AWS_ACCOUNT_LENS: LegacyLensAccountOnboarding,
  POTENTIAL_SAVINGS: PotentialSavingsDashboard,
  POTENTIAL_SAVINGS_DETAILS: PotentialSavingDetails,
  ENABLE_RI_OPS: RIOPSOnboard,
  VIRTUAL_RESERVATIONS: VirtualReservations,
  MONTHLY_REPORT: MonthlyReports,
  WAR_REPORT: WarReports,
  FINOPS_SUMMARY: ConsolidatedVRI,
  FINOPS_RECOMMENDATIONS: FinopsRecommendations,
  CUSTOMERS_HEALTH: CustomerHealthCheck,
  USERS: UserManagement,
  CUSTOMERS: Customers,
  BILLDESK_CUSTOMERS: CustomersBilldesk,

  PAYER_ACCOUNTS: PayerManagement,
  LIST: InvoiceListing,
  SETTINGS: InvoiceSettings,

  ADD_NEW_CUSTOMER: Customer,
  CUSTOMER_ACCOUNTS: Customer,
  EDIT_CUSTOMER: Customer,
  DETAIL_CUSTOMER: CreateCustomer,
  CUSTOMER_PREVIEW: CreateCustomer,

  PARTNER_MANAGEMENT: PartnerManagement,
  CUSTOMER_USERS: UserManagement,
  STORAGE: CostBreakup,
  REPORTS: TagReport,
  MISSED_SAVINGS: potentialSavingDetails,
  ADD_AWS_ACCOUNT: AddAWSAccount,
  INSTANCES: instances,
  CLOUDFRONT: Cloudfront,
  EBS,
  EBS_SNAPSHOT: Ebssnapshot,
  UPGRADE_VERSION,
  IDLE_NETWORK: IdleNetwork,
  CLB,
  PLAN_SELECTION: PlanSelection,
  PURCHASE_RESERVATIONS: VirtualReservationCart,
  ADD_NEW_USER: CreateUser,
  EDIT_USER: CreateUser,
  ADD_NEW_PARTNER: CreatePartner,
  ADD_NEW_PAYER: CreatePayer,
  EDIT_PAYER: CreatePayer,

  EDIT_PARTNER: CreatePartner,
  DAILY_BUDGET_ALERT: CostDeviationCreateDaily,
  MONTHLY_BUDGET_ALERT: CostDeviationCreateMonthly,
  ONDEMAND_DISCOUNTS_TEMPLATES: OnDemandDiscount,
  PAYER_ACCOUNTS_CUSTOMER: MPV,
  CUSTOMER_METERING_RECORD: MeteringRecords,
  S3,
  UPDATE_PLAN: UpdatePlan,
  CONFIGURE_DISCOUNTS: ConfigDisctounts,
  AWS_RESELLER_REGISTRATION: AwsResellerRegistration,
  // RESOURCE_WISE: ResourceWise,
  VIEW_CART: ViCart,
  CONFIGURE_EDP_DISCOUNT: EdpDiscounts,
  DISCOUNT_TEMPLATES: DiscountTemplate,
  CUSTOMER_BILLING: CustomerInvoiceRecord,
  RESOURCE_GROUP_WISE_BREAKUP: ResourceWise,
  SUBSCRIPTION_WISE_BREAKUP: AccountWise,
  PROJECT_WISE_BREAKUP: AccountWise,
  LOGGING_MONITORING: LoggingAndMonitoring,
  COST_ANALYSIS: CostExplorer,
  CONFIGURE_CHARGES_AND_REFUNDS: ConfigureChargesAndRefunds,
  CONFIGURE_CREDIT: ConfigureCredits,
  CONFIGURE_RESERVATION_DISCOUNT: ReservationDiscount,
  CLONE_DISCOUNT: CloneDiscount,
  // Azure
  ADD_AZURE_SUBSCRIPTION_AZURE_LENS: AzureOnboarding,
  AZURE_MANUAL_ONBOARDING: AzureManualOnboarding,
  ONBOARDING_SUMMARY: OnboardingSummary,
  //
  QUOTE_GENERATOR: Quotegenerator,
  CONFIGURE_GENERIC_DISCOUNT: ConfigureFlatDiscount,
  CREATE_ALERT: CostDeviationCreateMonthly,
  COST_DEVIATION_ALERT_EDIT: CostDeviationCreateMonthly,
  COST_DEVIATION_ALERT_CLONE: CostDeviationCreateMonthly,
  EDIT_ALERT_RI_EXPIRY: ExpiryDetailed,
  EDIT_ALERT_DAILY_SUMMARY_REPORT: SummaryReportDetailed,
  NEW_TEMPLATE: CreateTemplate,
  CONFIGURE_PPA: CloudfrontForm,
  COST_USAGE_REPORT: CostUsageReport,
  CREATE_USAGE_REPORT: CreateCostUsageReport,
  CREATE_PERMISSION: CreatePermissions,
  EDIT_PERMISSION: CreatePermissions,
  PERMISSIONS: PermissionListing,
  CREATE_PERMISSION_PREFERENCES: CreatePermissions,
  EDIT_PERMISSION_PREFERENCES: CreatePermissions,
  PERMISSIONS_PREFERENCES: PermissionListing,
  PERMISSIONS_TAGS: TagsListing,
  CREATE_PERMISSIONS_TAGS: CreateTags,
  EDIT_PERMISSIONS_TAGS: CreateTags,

  DATABASE_OLD: Database,
  HOURLY_SAVED_REPORTS: HourlySavedReports,
  RI_INVENTORY: RiInventory,
  MARKETPLACE: RIMarketplace,
  RDS_HOURLY: HourlyDashboard, // code for hourly dashboard
  ELASTICACHE_HOURLY: HourlyDashboard, // code for hourly dashboard
  OPENSEARCH_HOURLY: HourlyDashboard, // code for hourly dashboard
  REDSHIFT_HOURLY: HourlyDashboard, // code for hourly dashboard
  S3_HOURLY: HourlyDashboard,
  DATATRANSFER_HOURLY: HourlyDashboard, // code for hourly dashboard
  CONFIGURE_ADDITIONAL_DISCOUNT: ConfigureAdditionalDiscount,
  // For VRI lens
  SUMMARY: ReservedInstances,
  PURCHASE_RESERVATIONS_CUSTOMER: VRCustomer,
  VIEW_CART_CUSTOMER: ViCartCustomer,

  VRIDEFICIT: VriDeficit,
  SERVICE_WISE: CoverageServiceWise,
  PAYER_WISE: CoveragePayerWise,
  UNUSED_INVENTORY_SERVICE_WISE: UnusedInventoryServiceWise,
  UNUSED_INVENTORY_PAYER_WISE: UnusedInventoryPayerWise,
  // For adding custom Components for dev testing -- refer src/utils/howToAddNewRoute.js
  // SAMPLE_COMPONENT: ModuleControlGrid,
  // SAMPLE_COMPONENT_CHILD_1: ModuleControlGrid,
  // SAMPLE_COMPONENT_CHILD_2: ModuleControlGrid,
  TRACKER: RevenueTracker,
  FORECAST: RevenueForecast,
};

// NOTIFICATIONS --- Done
// RESERVATIONS_UTILIZATION --- or UTILIZATION ??
// SUMMARY --- or RESERVATIONS_SUMMARY ??
// RI_EXPIRY --- ?
// RI_UTILIZATION --- ?
// DAILY_SUMMARY_REPORT
// PREFERENCES
// PLAN_SELECTION --- Done
// TEMPLATES --- ?
// QUOTE_GENERATOR
// ADD_AWS_ACCOUNT_LENS --- ?
// PURCHASE_RESERVATIONS --- Done
// FINOPS --- or MONTHLY_REPORT?
// MY_USERS
// CUSTOMER_USERS
// ADD_NEW_CUSTOMER
// CUSTOMER_NAME
// CUSTOMER_METERING_RECORD
// ENTERPRISE_DISCOUNT_PROGRAM_EDP
// PRIVATE_PRICING_AGREEMENTS_PPA
// RESERVATION_PRICING
// DISCOUNTS_AND_CHARGES
// PREFERENCE_USER
// DAILY_BUDGET_ALERT
// MONTHLY_BUDGET_ALERT

export const preferenceList = {
  id: 41,
  key: "PREFERENCES",
  displayName: "Preferences",
  type: "PREFERENCES",
  icon: "https://static.dev.cloudonomic.net/dashboard-icons/preferences",
  exact: false,
  module: {
    id: 1,
    name: "lens",
  },
  description: "Preferences",
  title: "Preferences",
  tooltip: [],
  breadCrumbs: [],
  childDashboards: [
    {
      id: 44,
      key: "TAGS",
      displayName: "Tags",
      type: "PREFERENCES",
      icon: "https://static.dev.cloudonomic.net/dashboard-icons/tags",
      exact: false,
      module: {
        id: 1,
        name: "lens",
      },
      path: "preferences/tags",
      description: "Tags",
      title: "Tags",
      tooltip: [],
      breadCrumbs: [],
      childDashboards: [],
    },
    {
      id: 42,
      key: "USERS",
      displayName: "Users",
      type: "PREFERENCES",
      icon: "https://static.dev.cloudonomic.net/dashboard-icons/users",
      exact: false,
      module: {
        id: 1,
        name: "lens",
      },
      path: "preferences/user",
      description: "Users",
      title: "Users",
      tooltip: [],
      breadCrumbs: [],
      childDashboards: [],
    },
    {
      id: 43,
      key: "MAV",
      displayName: "MAV",
      type: "PREFERENCES",
      icon: "https://static.dev.cloudonomic.net/dashboard-icons/mav",
      exact: false,
      module: {
        id: 1,
        name: "lens",
      },
      path: "preferences/mav",
      description: "Multi Account View",
      title: "MAV",
      tooltip: [],
      breadCrumbs: [],
      childDashboards: [],
    },
  ],
};

// Routes requirement :
// Props: {
//   module: {
//     id: 1,
//     name: "LENS"
//   },
//   PageTitle: "displayName"
// }
// Path
// Component mapping
