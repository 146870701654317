import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";
import AzureLoader from "./CommonComponent/AzureLoading";
import urls from "../../../../../Services/axios/urls";
import { saveUserData } from "../../../../../Utils/userUtils";
import actions from "../../../../../Actions";
import Routes from "../../../../../Config/Routes";
import commonService from "../../../../../Services/commonService";
import { getEncryptedData, getToast } from "../../../../../Utils/commonUtils";
import { checkURLForToken, onboardingAxiosService } from "../util";

function AzureSsoLogin(props) {
  const navigate = useNavigate();

  const loadhandler = async () => {
    try {
      const partnerMeta = await commonService.partnerCreateUser();
      localStorage?.setItem(
        "partnerModuleNames",
        getEncryptedData(partnerMeta?.data?.data?.partnerModuleNames || [])
      );
      checkURLForToken();
      const apiUrl = `${urls?.CK_AZURE?.SSO_LOGIN}`;
      const response = await onboardingAxiosService.get(apiUrl);
      saveUserData(response, props, navigate);
    } catch (e) {
      getToast("error", e?.response?.data?.message || "Something went wrong");
      navigate(Routes.LOGIN);
    }
  };

  useEffect(() => {
    loadhandler();
  }, []);

  const message = "Logging you in, please wait a moment.";
  return <AzureLoader message={message} />;
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AzureSsoLogin);
