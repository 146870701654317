import { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { isDateValid, downloadExcel } from "../../../../../../Utils/commonUtils";
import {
  getSummaryCardsData,
  getCostByInstanceType,
  getCostByCluster,
  getCostByNameSpace,
  getCostByPurchase,
  getTop20InstanceTableData,
  exportCostByCluster,
  exportCostByNamespace,
  exportCostByRegion,
  exportCostByInstanceType,
  exportCostByPurchaseType,
} from "../common/service";
import { getCostRegion, getCudosDatabaseCards } from "../../Database/service";
import { CUDOS_EKS_GRAPH, SELECTED_TAB } from "../common/constant";
import { setGraphContent, getMonthCardsData } from "../common/helper";

const useEKS = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGranularityLoading, setIsGranularityLoading] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphDataConstant, setGraphDataConstant] = useState([]);
  const [summaryCards, setSummaryCards] = useState([]);
  const [activeGranulity, setActiveGranulity] = useState("Monthly");
  const [activeChartType, setActiveChartType] = useState("stackedcolumn2d");
  const [instanceTableData, setInstanceTableData] = useState({});
  const [recommendationSummary, setRecommendationSummary] = useState({});

  const granularityApiCall = async (granularity = "MONTHLY") => {
    setIsGranularityLoading(true);
    try {
      const [
        costByCluster,
        costByNamespace,
        costByRegions,
        costByInstanceType,
        costByPurchaseType,
      ] = await Promise.all([
        getCostByCluster(granularity),
        getCostByNameSpace(granularity),
        getCostRegion(SELECTED_TAB, granularity),
        getCostByInstanceType(granularity),
        getCostByPurchase(granularity),
      ]);
      setGraphData([
        costByCluster,
        costByNamespace,
        costByRegions,
        costByInstanceType,
        costByPurchaseType,
      ]);
    } finally {
      setIsGranularityLoading(false);
    }
  };

  const cardTableApiCall = async () => {
    const [cards, summaryData, top20ResourcesTable] = await Promise.all([
      getCudosDatabaseCards(SELECTED_TAB, getMonthCardsData),
      getSummaryCardsData(SELECTED_TAB),
      getTop20InstanceTableData(SELECTED_TAB),
    ]);
    const updatedGraphDataConstant = cloneDeep(CUDOS_EKS_GRAPH(activeChartType));
    setCardsData(cards.cardsData);
    setRecommendationSummary(cards.recommendationSummary);
    setSummaryCards(summaryData);
    setGraphDataConstant(updatedGraphDataConstant);
    setInstanceTableData(top20ResourcesTable);
  };

  const initialApiCall = async (granularity) => {
    setIsLoading(true);
    await Promise.all([cardTableApiCall(), granularityApiCall(granularity)]);
    setIsLoading(false);
  };

  const onChartTypeChange = async (chartType) => {
    setIsGranularityLoading(true);
    const graphDataConstantCopy = graphDataConstant.map((item) => ({
      ...item,
      type: chartType,
    }));
    await setGraphDataConstant(graphDataConstantCopy);
    await setActiveChartType(chartType);
    setIsGranularityLoading(false);
  };

  const selectedDate = async () => {
    if (isDateValid()) {
      initialApiCall(activeGranulity.toUpperCase());
    }
  };

  useEffect(() => {
    initialApiCall(activeGranulity.toUpperCase());
  }, []);

  const onGranulityChange = (item) => {
    granularityApiCall(item?.toUpperCase());
    setActiveGranulity(item);
  };

  const onExcelDownload = async (graphName) => {
    setGraphContent({
      graphName,
      graphDataConstant,
      graphData,
      loading: true,
      setGraphData,
      setGraphDataConstant,
    });
    let response = {};
    try {
      switch (graphName) {
        case "costByClusters":
          response = await exportCostByCluster(SELECTED_TAB, activeGranulity.toUpperCase());
          break;
        case "costByNamespace":
          response = await exportCostByNamespace(SELECTED_TAB, activeGranulity.toUpperCase());
          break;
        case "costByRegions":
          response = await exportCostByRegion(SELECTED_TAB, activeGranulity.toUpperCase());
          break;
        case "costByInstanceType":
          response = await exportCostByInstanceType(SELECTED_TAB, activeGranulity.toUpperCase());
          break;
        case "costByPurchaseType":
          response = await exportCostByPurchaseType(SELECTED_TAB, activeGranulity.toUpperCase());
          break;
        default:
          break;
      }
      downloadExcel(response);
    } finally {
      setGraphContent({
        graphName,
        graphDataConstant,
        graphData,
        loading: false,
        setGraphData,
        setGraphDataConstant,
      });
    }
  };

  return {
    isLoading,
    isGranularityLoading,
    selectedDate,
    cardsData,
    summaryCards,
    recommendationSummary,
    graphData,
    graphDataConstant,
    onGranulityChange,
    activeGranulity,
    onChartTypeChange,
    activeChartType,
    instanceTableData,
    onExcelDownload,
  };
};

export default useEKS;
