import React, { Component } from "react";
import moment from "moment";
import {
  numberWithCommasandDecimal,
  numberWithCommasandDecimalPercentage,
} from "../../../../../Utils/commonUtils";
import { formatValue, getDoughnutChart } from "./util";
import TooltipPopup from "../../../../Common/Tooltip";

class TotalRiCoverageCost extends Component {
  renderTooltipChild = (data) => {
    const queryParams = new URLSearchParams(window.location.search);
    const updatedselectedDate = moment([
      queryParams.get("year"),
      queryParams.get("month") - 1,
    ]).format("MMMM YYYY");
    return (
      <div className="tooltip-container">
        <div className="tool_heading">{updatedselectedDate}</div>
        <div className="flex items-center item">
          <div className="flex items-center">
            <div className="w-[8px] h-[8px] bg-[#A3DC29] rounded-[2px]" />
            <div className="text-[#737373] text-[11px] font-[400] ml-[12px]">
              Reservation Covered Cost
            </div>
          </div>
          <div className="text-[#2B2B2B] text-[11px] font-[500]">
            {formatValue(
              Number(data?.overallTotal) - Number(data?.onDemand),
              numberWithCommasandDecimal
            )}
          </div>
        </div>
        <div className="flex items-center item">
          <div className="flex items-center">
            <div className="w-[8px] h-[8px] bg-[#2498FE] rounded-[2px]" />
            <div className="text-[#737373] text-[11px] font-[400] ml-[12px]">
              Remaining OnDemand Cost
            </div>
          </div>
          <div className="text-[#2B2B2B] text-[11px] font-[500]">
            {formatValue(data?.onDemand, numberWithCommasandDecimal)}
          </div>
        </div>
        <div className="flex items-center item total">
          <div className="text-[#2B2B2B] text-[12px] font-[400]">Total Cost</div>
          <div className="text-[#2B2B2B] text-[12px] font-[500]">
            {formatValue(data?.overallTotal, numberWithCommasandDecimal)}
          </div>
        </div>
      </div>
    );
  };

  renderContent = (i) => (
    <div
      className="h-[20px] mb-[16px] coloredDiv mt-[-24px]"
      style={{ "--percentage": `${i?.coveragePercentage}%` }}
    />
  );

  render() {
    const {
      data: { stackedGraphData, graphData, riCoverageData },
    } = this.props;
    return (
      <div className="flex flex-wrap gap-x-[16px] gap-y-[16px] h-[466px] doughnut-chart-wrapper w-[49%] p-[12px]">
        <div className="flex w-[100%] ri-coverage-donut-chart-wrapper">
          {getDoughnutChart(graphData)}
          <div className="ri-coverage-description">
            <div className="text-[#2B2B2B] text-[16px] font-[700]">Total RI Coverage in Cost</div>
            <p className="text-[#737373] text-[12px]">Overall instance usage is covered by RIs</p>
            <div className="flex justify-between">
              <div className="mt-[20px]">
                <div className="flex items-center">
                  <div className="w-[8px] h-[8px] bg-[#A3DC29] rounded-[2px]" />
                  <div className="text-[#2B2B2B] text-[12px] font-[500] ml-[4px] mt-[2px]">
                    Reservation Covered Cost
                  </div>
                </div>
                <div className="text-[#0A3CA2] text-[24px] font-[700]">
                  {formatValue(
                    riCoverageData?.totalRdsReservationPercentage,
                    numberWithCommasandDecimalPercentage
                  )}
                </div>
                <div className="text-[#737373] text-[14px] font-[500]">
                  {formatValue(riCoverageData?.totalRdsReservationCost, numberWithCommasandDecimal)}
                </div>
              </div>
              <div className="mt-[20px]">
                <div className="flex items-center">
                  <div className="w-[8px] h-[8px] bg-[#2498FE] rounded-[2px]" />
                  <div className="text-[#2B2B2B] text-[12px] font-[500] ml-[4px] mt-[2px]">
                    Remaining OnDemand Cost
                  </div>
                </div>
                <div className="text-[#0A3CA2] text-[24px] font-[700]">
                  {formatValue(
                    riCoverageData?.totalRdsOnDemandCostPercentage,
                    numberWithCommasandDecimalPercentage
                  )}
                </div>
                <div className="text-[#737373] text-[14px] font-[500]">
                  {formatValue(riCoverageData?.totalRdsOnDemandCost, numberWithCommasandDecimal)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-container">
          <p className="heading-bottom-container">
            RI Coverage per region | engine | Instance Type/Family
          </p>
          <div className={`h-[225px] ${stackedGraphData?.length && "overflow-auto"}`}>
            {stackedGraphData?.length ? (
              stackedGraphData?.map((i, index) => (
                <div>
                  <div
                    className="text-[#2B2B2B] text-[12px] font-[500] mb-[4px]"
                    key={`stacked-${index + 1}`}
                  >
                    {i?.region}
                    {i?.dbEngine ? ` | ${i?.dbEngine}` : ""} |{" "}
                    {i?.instanceType || i?.instanceFamily}
                  </div>
                  <TooltipPopup
                    style={{
                      background: "#ffffff",
                      padding: "0px",
                    }}
                    arrow={false}
                    placement="bottom"
                    content={() => this.renderTooltipChild(i)}
                    icon={this.renderContent(i)}
                  />
                </div>
              ))
            ) : (
              <div className="no_data">
                <h5>No data Available</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TotalRiCoverageCost;
