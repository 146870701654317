import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../../Assets/images/ic-info.svg";
import { ReactComponent as CopyIcon } from "../../../Assets/icons/copy-icon-primary-color.svg";

import "./style.scss";
import { getToast } from "../../../Utils/commonUtils";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

function TooltipV2(props) {
  const {
    displayText,
    children,
    parentClass,
    tooltipClass,
    popoverClassNames,
    tooltipid,
    anchorOrigin = {
      vertical: "center",
      horizontal: "left",
    },
    transformOrigin = {
      vertical: "center",
      horizontal: "right",
    },
    showTooltipIcon = true,
    copyIcon = false,
    data,
    copyText = "Copied",
    tooltipOnDisplayText = false,
    popoverAction,
    onPopoverEntering,
    onPopoverExit,
  } = props;
  const showMore = useRef();

  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const classes = useStyles();

  const copy = (copyData = "", message = "Copied") => {
    navigator.clipboard.writeText(copyData);
    getToast("success", message);
  };

  return (
    <div className={`list-popup-wrapper auto-renew-tooltip-wrapper ${tooltipClass}`} id={tooltipid}>
      {tooltipOnDisplayText ? (
        <span
          className="display-text"
          ref={popoverAnchor}
          aria-owns="auto-renew-popover"
          aria-haspopup="true"
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          {displayText() || ""}
        </span>
      ) : (
        <span className="display-text">{displayText() || ""}</span>
      )}

      <div className="pop-up ml-[3px] mb-[3px]">
        {showTooltipIcon && (
          <span
            ref={popoverAnchor}
            aria-owns="auto-renew-popover"
            aria-haspopup="true"
            onMouseEnter={popoverEnter}
            onMouseLeave={popoverLeave}
          >
            {copyIcon ? (
              <CopyIcon
                onClick={() => {
                  copy(data, copyText);
                }}
                ref={showMore}
                className="show-more-copy !w-[13px] !h-[13px]"
              />
            ) : (
              <InfoIcon ref={showMore} className="show-more !w-[13px] !h-[13px]" />
            )}
          </span>
        )}

        <Popover
          id="auto-renew-popover"
          action={(actions) => {
            popoverAction?.(actions);
          }}
          className={`${classes.popover} ${popoverClassNames} auto-renew-tooltip-popover
            before:absolute bottom-6 before:bottom-[-24px] before:right-[4px] before:transform before:rotate-[135deg] before:left-auto`}
          classes={{
            paper: classes.popoverContent,
          }}
          open={openedPopover}
          TransitionProps={{
            onEntering: (popoverElem) => {
              onPopoverEntering?.(popoverElem);
            },
            onExited: (popoverElem) => {
              onPopoverExit?.(popoverElem);
            },
          }}
          anchorEl={popoverAnchor.current}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
        >
          <div className={`${parentClass}`}>{children}</div>
        </Popover>
      </div>
    </div>
  );
}

export default TooltipV2;
