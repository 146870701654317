import React from "react";
import MonthCardImage from "../../Assets/monthCardBackground.svg";
import ForecastCardImage from "../../Assets/forecastCardBackground.svg";
import ForecastCard from "../../Assets/forecast.svg";
import { numberWithCommasandDecimal } from "../../../../../../../Utils/commonUtils";
import { ReactComponent as ArrowDownGreen } from "../../Assets/arrow-down-green-filled.svg";
import { ReactComponent as CardTopArrow } from "../../../../../../../Assets/icons/others/card-arrow-top.svg";
import "./style.scss";

function Cards(props) {
  const { cardsData = [] } = props;

  const renderHeading = (data = "") => (
    <div className="text-[12px] font-semibold text-[#0C477E]">{data}</div>
  );

  const renderAmount = (data = 0) => (
    <div className="text-[15px] font-normal mb-[5px] mt-[5px] text-[#000000]">
      {numberWithCommasandDecimal(data, 0)}
    </div>
  );

  const renderTrendPercentage = (percentage, differenceBetweenValues) => (
    <div>
      {differenceBetweenValues > 0 ? (
        <div className="flex items-center text-[#E92013] gap-[2px]">
          <CardTopArrow className="h-[7px] w-[6px]" />
          <span className="">{percentage?.toFixed(0)}%</span>
        </div>
      ) : (
        <div className="flex items-center text-[#32B556] gap-[2px]">
          <ArrowDownGreen className="h-[7px] w-[6px]" />
          <span className="">{percentage?.toFixed(0)}%</span>
        </div>
      )}
    </div>
  );

  const renderTrendCard = (data) => (
    <>
      <span className="text-[#4B5157]">
        {data.differenceBetweenValues >= 0 ? "+" : "-"}
        {numberWithCommasandDecimal(Math.abs(data.differenceBetweenValues), 0)}
      </span>
      {renderTrendPercentage(data.percentage, data.differenceBetweenValues)}
    </>
  );

  const renderForecastData = (data) => (
    <div
      style={{
        backgroundImage: `url(${ForecastCard})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="w-max rounded-[5px]"
    >
      <div className="py-[5px] px-[8px]">
        {renderHeading("FORECAST")}
        {renderAmount(data.forecastValue)}
        <div className="flex gap-[10px] rounded-[5px] py-[4px] px-[10px] bg-[#fff] w-max text-[12px]">
          {renderTrendCard(data)}
        </div>
      </div>
    </div>
  );

  const renderCardsData = (data) =>
    !data.isForecastPresent ? (
      <div className="pt-[5px]">
        {renderHeading(data?.month?.toUpperCase()) || "--"}
        {renderAmount(data?.amount ?? "--")}
        <div className="flex gap-[10px] rounded-[5px] py-[4px] px-[10px] bg-[#F5F9FD] w-max text-[12px]">
          {renderTrendCard(data)}
        </div>
      </div>
    ) : (
      <div className="flex justify-between">
        <div className="pt-[5px] w-[40%]">
          {renderHeading(data?.month?.toUpperCase()) || "--"}
          {renderAmount(data?.amount ?? "--")}
        </div>
        {renderForecastData(data)}
      </div>
    );

  return (
    <div className="ck-cudos-month-cards-wrapper">
      {cardsData?.map((card) => (
        <div
          className={`ck-cudos-months-cards ${card.isForecastPresent ? "w-[225px]" : "w-[125px]"}`}
          style={{
            backgroundImage: `url(${card.isForecastPresent ? ForecastCardImage : MonthCardImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="mx-[7px] my-[7px]">
            {card.isNABlock ? (
              <div className="pt-[5px]">
                {renderHeading(card.month?.toUpperCase())}
                <span>--</span>
              </div>
            ) : (
              renderCardsData(card)
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Cards;
