import patterns from "./patterns";

const ruleExecutionsOrder = [
  "required",
  "dependentFields",
  "commaSeperatedPattern",
  "maskingPattern",
  "pattern",
  "multiSelect",
  "maximum",
  "minimum",
  "equality",
  "allowedFileType",
  "allowedFileSize",
  "maxAmount",
  "minAmount",
  "percentage",
  "configurableTableValidation",
  "commitmentTableValidation",
  "creditOrderingValidation",
  "OptionInputFieldValidation",
  "selectionTableValidation",
];

const stringInterpolation = (errorMessage, fieldName) =>
  errorMessage?.replace("{{fieldName}}", fieldName?.toLowerCase());

const isEmpty = (value) => {
  if (value instanceof Array) {
    return !value?.length;
  }

  if (value && typeof value === "object") {
    if (value instanceof File) {
      return false;
    }
    return !Object.keys(value)?.length;
  }

  return !value;
};

const required = (key, value, errorMessage, placeHolder) => {
  const errors = {};
  if (isEmpty(value)) {
    errors[key] = stringInterpolation(errorMessage, placeHolder);
  }
  return errors;
};

const pattern = (key, value, errorMessage, ruleValue, placeHolder) => {
  const errors = {};
  if (typeof ruleValue === "object") {
    if (value?.length && !value?.match(ruleValue)) {
      errors[key] = stringInterpolation(errorMessage, placeHolder);
    }
  } else if (!new RegExp(ruleValue)?.test(value)) {
    errors[key] = stringInterpolation(errorMessage, placeHolder);
  }

  return errors;
};

const commaSeperatedPattern = (key, value, message, rule, label) => {
  const errors = {};
  const commaSeparatedValue = value.split(",");
  const isValid = commaSeparatedValue.every((cValue) => {
    const errorsObj = pattern(key, cValue.trim(), message.pattern, rule.pattern, label);
    const error = errorsObj[key];
    return !error;
  });

  if (!isValid) {
    errors[key] = stringInterpolation(message.pattern, label);
  }

  if (rule.threshold && commaSeparatedValue.length > rule.threshold) {
    errors[key] = stringInterpolation(message.threshold, label);
  }

  return errors;
};

/**
 * deleting confirm password error when password field is changed,
 * and password matches with confirm password field
 */
const equality = (value, errorMessage, ruleValue, values, formErrors) => {
  const errors = {};
  const formErrorsClone = formErrors;
  const { targetId, displayErrorOn } = ruleValue;

  if (value !== values[targetId]) {
    errors[displayErrorOn] = errorMessage;
  } else {
    delete formErrorsClone[targetId];
  }
  return errors;
};

const validateFileType = (key, value, ruleValue, errorMessage) => {
  const errors = {};

  const extFile = value.name.split(".").pop();

  if (!ruleValue.includes(extFile)) {
    errors[key] = errorMessage;
  }
  return errors;
};

export const validateFileSize = (key, value, ruleValue, errorMessage) => {
  const errors = {};

  const sizeInMB = (value.size / (1024 * 1024)).toFixed(1);

  if (sizeInMB > ruleValue) {
    errors[key] = errorMessage;
  }

  return errors;
};

const validateMinimum = (key, value, ruleValue, errorMessage) => {
  const errors = {};
  if (value.length < ruleValue) {
    errors[key] = errorMessage.replace("{{digit}}", `${ruleValue}`);
    errors[key] = errors[key]?.replace("{{fieldName}}", `${key}`);
  }
  return errors;
};

export const validateMaximum = (key, value, ruleValue, errorMessage) => {
  const errors = {};
  if (value?.length > ruleValue) {
    errors[key] = errorMessage.replace("{{digit}}", `${ruleValue}`);
  }
  return errors;
};

const findStaticCharacters = (elementSize, translationPattern) => {
  const staticChars = [];
  const uniqueChars = new Set(elementSize.split(""));
  uniqueChars.forEach((char) => {
    if (!translationPattern[char]) {
      staticChars.push(char);
    }
  });
  return staticChars;
};

const getValidationBlock = (value, elementSize, translationPattern, changeIndex) => {
  const staticChars = findStaticCharacters(elementSize, translationPattern);
  const validationBlocks = [];
  let start = 0;
  const end = value.length - 1;
  for (let i = 0; i < value.length; i += 1) {
    if (staticChars.includes(value[i])) {
      // divide the blocks based on static characters of masking pattern
      validationBlocks.push([start, i - 1]);
      if (typeof changeIndex === "number" && changeIndex >= start && changeIndex < i) {
        return [start, changeIndex];
      }
      start = i + 1;
    }
  }
  if (!validationBlocks.length) {
    validationBlocks.push([0, end]);
  }
  return typeof changeIndex === "number" ? [0, changeIndex] : [0, end];
};

const validateMaskingPattern = (value, key, errorMessage, maskingPattern, index) => {
  let errors = {};
  const { elementSize, translationPattern } = maskingPattern;
  let validationBlock;
  if (index !== -1) {
    validationBlock = getValidationBlock(value, elementSize, translationPattern, index);
  } else {
    validationBlock = getValidationBlock(value, elementSize, translationPattern, undefined);
  }

  if (elementSize && translationPattern) {
    for (let i = validationBlock[0]; i <= validationBlock[1]; i += 1) {
      const patternToExecute = translationPattern[elementSize[i]]?.pattern;
      const valueToTest = value[i];

      if (patternToExecute) {
        errors = pattern(key, valueToTest, errorMessage, patternToExecute);
      } else if (valueToTest !== elementSize[i]) {
        errors[key] = errorMessage;
      }

      if (Object.keys(errors).length) {
        break;
      }
    }
  }

  return errors;
};

const generateError = (value) => {
  const keys = Object.keys(value);
  return keys.some((key) => value[key].value && value[key].value.length > 0);
};

const multiSelect = (key, value, ruleValue, errorMessage) => {
  const errors = {};
  if (!generateError(value)) {
    errors[key] = errorMessage.replace("{{digit}}", `${ruleValue}`);
  }
  return errors;
};

const removeEmptyStrings = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

const configurableTable = (key, value, three, four, formEle) => {
  const { enableAos, handleUpcomingRegions } = formEle;
  const error = { [key]: {} };
  const formData = value?.values;
  const updatedNewRegionConfig = value?.newRegionConfig || {};
  formData?.forEach((item) => {
    if (Object.keys(removeEmptyStrings(item)).length === 1) {
      error[key][item.childRegions] = "Enter pricing in at least one of the field";
    } else {
      Object.keys(removeEmptyStrings(item)).forEach((itemKey) => {
        if (itemKey !== "childRegions") {
          if (
            value.configurationType === "discount" &&
            !patterns.positiveNumberLessThen100.test(item[itemKey])
          ) {
            error[key][item.childRegions] = "Invalid Input";
          } else if (!patterns.decimalLimit4.test(item[itemKey])) {
            error[key][item.childRegions] = "Invalid Input";
          }
        }
      });
    }
  });

  if (enableAos && handleUpcomingRegions && updatedNewRegionConfig) {
    if (value?.newRegion) {
      Object.keys(removeEmptyStrings(updatedNewRegionConfig))?.forEach((itemKey) => {
        if (
          value.configurationType === "discount" &&
          !patterns.positiveNumberLessThen100?.test(updatedNewRegionConfig[itemKey])
        ) {
          error[key].newRegionError = "Please enter input less then 100";
        } else if (!patterns.decimalLimit4?.test(updatedNewRegionConfig[itemKey])) {
          error[key].newRegionError = "Please enter valid input";
        }
      });
      if (Object.keys(removeEmptyStrings(updatedNewRegionConfig)).length === 0) {
        error[key].newRegionError = "Plese Enter some value for New regions";
      }
    }

    if (value?.aosSize && !patterns.numberGreaterThanZero.test(value?.aosSize)) {
      error[key].aosSize = "Please enter Valid AOS";
    }
  }

  if (Object.keys(error[key]).length === 0) {
    return {};
  }
  return error;
};

const tbToGb = (tb) => {
  const gb = tb * 1024;
  return gb;
};

const commitmentTable = (key, value) => {
  const formData = value.tableData;
  let prevQty = null;
  const error = {};
  const updatedformData = formData.map((item) => {
    if (item?.unit?.value === "TB_YEAR") {
      return { ...item, qty: tbToGb(item?.qty) };
    }
    return item;
  });
  const errorObj = updatedformData
    .map((row, index) => {
      if (row.qty === null || row.qty === undefined || row.qty === "") {
        return { [index]: "Enter valid commitment" };
      }
      if (!/^(?=.*[1-9])\d*\.?\d{0,4}$/.test(row.qty)) {
        return { [index]: "Invalid Input" };
      }
      if (Number(row.qty) <= 0) {
        return { [index]: "Invalid Input" };
      }
      if (prevQty !== null && Number(row.qty) < Number(prevQty)) {
        return { [index]: "Value must be greater or equal than previous commitment" };
      }
      prevQty = row.qty;
      return null;
    })
    .reduce((acc, curr) => {
      if (curr !== null) {
        Object.assign(acc, curr);
      }
      return acc;
    }, {});
  if (value?.shortfallCharge !== "NEVER" && Object.keys(value?.customerAccount ?? {})?.length < 1) {
    errorObj.customerAccount = "Please select Customer Account";
  }
  if (Object.keys(errorObj).length > 0) {
    error[key] = errorObj;
  }
  return error;
};

const dependentFields = (key, value, message, label, props, dependentID) => {
  const selfValidations = required(key, value, message, label);
  const dependentField = required(dependentID, props[dependentID], message, label);

  if (!Object.keys(selfValidations).length || !Object.keys(dependentField).length) {
    return {};
  }

  return {
    ...selfValidations,
    ...dependentField,
  };
};

const creditOrdering = (key, value, errorMessage) => {
  const errors = {};

  if (key === "ordering" && value.value === "yes" && value.count === false) {
    errors[key] = errorMessage;
  }

  return errors;
};

export const OptionInputFieldValidation = (name, value, errorMessage) => {
  const errors = {};

  if (value?.value === 0 || value?.value <= 0) {
    errors[name] = errorMessage;
  }
  return errors;
};

const selectionTable = (key, value, errorMessage) => {
  const errors = {};

  value.options.forEach((option) => {
    const hasValidColumn = Object.values(option?.column).some(
      (columnValue) => columnValue === true
    );
    if (!hasValidColumn) {
      errors[key] = errorMessage;
    }
  });

  return errors;
};
const percentage = (key, value, ruleValue, errorMessage, props, label) => {
  const errors = {};
  const positiveFloatPattern = /^\d+(\.\d*)?$/;
  if (!positiveFloatPattern.test(value) || value < 0 || value > ruleValue) {
    if (value > ruleValue) {
      const cloneProp = props;
      cloneProp[key] = ruleValue;
    } else {
      errors[key] = stringInterpolation(errorMessage, label);
    }
  }
  return errors;
};

const executeValidations = (rule, props, formElement, formErrors, changeIndex) => {
  const {
    name: key,
    validations: { messages, rules = {} },
    label,
  } = formElement;

  const value = props[key];
  const message = messages[rule];

  const validationMap = {
    required: () => required(key, value, message, label),
    pattern: () => pattern(key, value, message, rules[rule], label),
    equality: () => equality(value, message, rules[rule], props, formErrors),
    allowedFileType: () => validateFileType(key, value, rules[rule], message),
    allowedFileSize: () => validateFileSize(key, value, rules[rule], message),
    minimum: () => validateMinimum(key, value, rules[rule], message),
    maximum: () => validateMaximum(key, value, rules[rule], message),
    maskingPattern: () => validateMaskingPattern(value, key, message, rules[rule], changeIndex),
    multiSelect: () => multiSelect(key, value, rules[rule], message),
    configurableTableValidation: () => configurableTable(key, value, message, label, formElement),
    commitmentTableValidation: () => commitmentTable(key, value, message, label),
    dependentFields: () => dependentFields(key, value, message, label, props, rules[rule]),
    creditOrderingValidation: () => creditOrdering(key, value, message, label),
    OptionInputFieldValidation: () => OptionInputFieldValidation(key, value, message, label),
    selectionTableValidation: () => selectionTable(key, value, message, label),
    commaSeperatedPattern: () => commaSeperatedPattern(key, value, message, rules[rule], label),
    percentage: () => percentage(key, value, rules[rule], message, props, label),
  };

  return validationMap[rule] ? validationMap[rule]() : {};
};

export default (formErrors, props, formValidations, changeIndex) => {
  let errors = {};

  formValidations?.forEach((formElement) => {
    const { validations: { rules = {} } = {}, name, hidden } = formElement;

    if (!hidden && (rules.required || rules.dependentFields || (!rules.required && props[name]))) {
      ruleExecutionsOrder.forEach((ruleKey) => {
        if (errors?.fields?.[formElement.name]) {
          return;
        }

        if (!formElement.validations.rules[ruleKey]) {
          return;
        }

        const error = executeValidations(ruleKey, props, formElement, formErrors, changeIndex);
        errors = { fields: { ...errors.fields, ...error }, ruleKey };
      });
    }
  });

  return errors;
};
