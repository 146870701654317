import React from "react";
import "./style.scss";
import serviceUnavailableLogo from "../../../Assets/icons/ic-503.png";
import { strapiBasePath } from "../../../Services/Strapi/urls";

function InternalError503(props) {
  const { strapiData = {} } = props;

  const {
    subdescription = "The server is temporarily busy. try again later!",
    description = "Service Unavailable",
  } = strapiData;

  const logo = strapiData?.logo?.data?.attributes?.url
    ? `${strapiBasePath}${strapiData?.logo?.data?.attributes?.url}`
    : serviceUnavailableLogo;

  return (
    <div className="page-not-found errorPageNotFound">
      <div className="blurbg" />
      <div className="not-foundIcon">
        <img src={logo} alt="logo" />
      </div>
      <div className="internalError-heading">
        <h3 className="error-heading">{description}</h3>
      </div>
      <div className="usefullLinks-container">
        <div className="internalError-texts">
          <p>{subdescription}</p>
        </div>
        {/* <div className="error-button-wrapper">
          <a href="/dashboard/billingSummary" className="internalError-button">
            Back to home
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default InternalError503;
