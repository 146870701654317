import React from "react";
import { ReactComponent as BarChartImage } from "../../../../../Assets/icons/bar-chart-toggle.svg";
import { ReactComponent as LineChartImage } from "../../../../../Assets/icons/line-chart-toggle.svg";
import { ReactComponent as TableImage } from "../../../../../Assets/icons/table-chart-toggle.svg";
import { getCurrencySymbol } from "../../../../../Utils/commonUtils";

export const CUDOS_DATABASE_GRAPH = [
  {
    heading: "Total Cost by Accounts",
    tabs: true,
    type: "stackedcolumn2d",
    yAxisName: "Cost (Sum)",
    xAxisName: "Days",
    name: "costByAccounts",
    wrapperClass: "w-[100%] !mt-[16px] !mb-[0px] graph-wrapper-style",
    firstHeader: {
      key: "rowName",
      value: "Account Name",
      align: "Left",
    },
    imagesList: [
      {
        text: "stackedcolumn2d",
        imageUrl: <BarChartImage className="tabSelector_option" />,
      },
      {
        text: "multipleLineChart",
        imageUrl: <LineChartImage className="tabSelector_option" />,
      },
      {
        text: "table",
        imageUrl: <TableImage className="tabSelector_option" />,
      },
    ],
  },
  {
    heading: "Total Cost by Regions",
    tabs: true,
    type: "groupChart",
    yAxisName: "Cost (Sum)",
    xAxisName: "Months",
    name: "costByRegions",
    firstHeader: {
      key: "rowName",
      value: "Regions",
      align: "Left",
    },
    wrapperClass: "w-[49%] !m-[0px] graph-wrapper-style",
    imagesList: [
      {
        text: "groupChart",
        imageUrl: <BarChartImage className="tabSelector_option" />,
      },
      {
        text: "table",
        imageUrl: <TableImage className="tabSelector_option" />,
      },
    ],
  },
  {
    heading: "Total Cost by Engines",
    tabs: true,
    type: "groupChart",
    yAxisName: "Cost (Sum)",
    xAxisName: "Months",
    name: "costByEngines",
    wrapperClass: "w-[49%] !m-[0px] graph-wrapper-style",
    firstHeader: {
      key: "rowName",
      value: "Engine",
      align: "Left",
    },
    imagesList: [
      {
        text: "groupChart",
        imageUrl: <BarChartImage className="tabSelector_option" />,
      },
      {
        text: "table",
        imageUrl: <TableImage className="tabSelector_option" />,
      },
    ],
  },
  {
    heading: "Cost by Instance Type",
    tabs: true,
    type: "stackedcolumn2d",
    yAxisName: "Cost (Sum)",
    firstHeader: {
      key: "rowName",
      value: "Instance Type",
      align: "Left",
    },
    xAxisName: "Days",
    name: "costByInstanceType",
    wrapperClass: "w-[100%] !mb-[0px] graph-wrapper-style !mt-[0]",
    imagesList: [
      {
        text: "stackedcolumn2d",
        imageUrl: <BarChartImage className="tabSelector_option" />,
      },
      {
        text: "multipleLineChart",
        imageUrl: <LineChartImage className="tabSelector_option" />,
      },
      {
        text: "table",
        imageUrl: <TableImage className="tabSelector_option" />,
      },
    ],
  },
  {
    heading: "Cost By Top 5 Usage",
    tabs: true,
    type: "stackBarChart",
    yAxisName: "Cost",
    xAxisName: "Months",
    firstHeader: {
      key: "rowName",
      value: "Service Name",
      align: "Left",
    },
    name: "costByTop5Usage",
    wrapperClass: "w-[49%] !m-[0px] graph-wrapper-style cost-by-top-5-usage",
    imagesList: [
      {
        text: "stackBarChart",
        imageUrl: <BarChartImage className="tabSelector_option" />,
      },

      {
        text: "table",
        imageUrl: <TableImage className="tabSelector_option" />,
      },
    ],
    list: [
      { label: "Cost", value: "COST" },
      { label: "Usage", value: "USAGE" },
    ],
    toggleType: "toggle",
    active: "COST",
  },
];

export const totalCostByRegion = [
  {
    heading: "Total Cost by Regions",
    tabs: true,
    type: "groupChart",
    yAxisName: "Cost (Sum)",
    xAxisName: "Months",
    name: "totalCostByRegion",
    imagesList: [
      {
        text: "groupChart",
        imageUrl: <BarChartImage className="tabSelector_option" />,
      },
      {
        text: "table",
        imageUrl: <TableImage className="tabSelector_option" />,
      },
    ],
  },
];

export const top20InstanceHeadersGroup = {
  RDS: [
    {
      Header: "Account ID",
      accessor: "linked_account_id",
      id: "linked_account_id",
      sortedIcon: true,
      Footer: "Others",
      SecondaryFooter: "Total",
      align: "Left",
    },
    {
      Header: "Instance Name",
      accessor: "instance_name",
      id: "instance_name",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Region",
      accessor: "region",
      id: "region",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "DB Engine",
      accessor: "db_engine",
      id: "db_engine",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Instance Type",
      accessor: "instance_type",
      id: "instance_type",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Pricing Type",
      accessor: "pricing_type",
      id: "pricing_type",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Availability",
      accessor: "multi_az",
      id: "multi_az",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Quantity (Hrs)",
      accessor: "usage_hours",
      id: "usage_hours",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Total Cost",
      id: "cost",
      accessor: (data) =>
        `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      sortedIcon: true,
    },
  ],
  ElastiCache: [
    {
      Header: "Account ID",
      accessor: "linked_account_id",
      id: "linked_account_id",
      sortedIcon: true,
      Footer: "Others",
      SecondaryFooter: "Total",
      align: "Left",
    },
    {
      Header: "Instance Name",
      accessor: "instance_name",
      id: "instance_name",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Region",
      accessor: "region",
      id: "region",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Cache Engine",
      accessor: "cache_engine",
      id: "db_engine",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Instance Type",
      accessor: "instance_type",
      id: "instance_type",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Pricing Type",
      accessor: "pricing_type",
      id: "pricing_type",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Quantity (Hrs)",
      accessor: "usage_hours",
      id: "usage_hours",
      sortedIcon: true,
      Footer: "",
      SecondaryFooter: "",
      align: "Left",
    },
    {
      Header: "Total Cost",
      id: "cost",
      accessor: (data) =>
        `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      sortedIcon: true,
    },
  ],
};

export const TABS = [
  {
    label: "RDS",
    value: "rds",
    name: "Amazon Relational Database Service",
  },
  {
    label: "ElastiCache",
    value: "elasticache",
    name: "ElastiCache",
  },
  // {
  //   label: "Naptune",
  //   value: "naptune",
  // },
  // {
  //   label: "Open Search Service",
  //   value: "open_search_service",
  // },
  // {
  //   label: "Redshift",
  //   value: "redshift",
  // },
  // {
  //   label: "DocumentDB (with MongoDB Capebility)",
  //   value: "documentdb_(with_mongodb_capebility)",
  // },
];

export const DAILY_MONTHLY_TOGGLE = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const DATABASE_HEADING = "You can reduce monthly cost";
export const DATABASE_SUB_HEADING = "By migrating to the latest generation instance types";
export const RICoverageCost = {
  heading: "",
  tabs: true,
  type: "doughnutChart",
  name: "riCoverageCostChart",
  wrapperClass: "w-[48%] !m-[0px]",
  imagesList: [],
};

export const MOCK = {
  data: {
    message: "Request processed successfully.",
    status: 200,
    data: {
      totalCosts: {
        usage_hours: 120267.433105,
        cost: 82255.530252921,
      },
      data: [
        {
          linked_account_id: "244051943027",
          instance_name: "prod-zepto-db-aurora-writer",
          region: "Asia Pacific (Mumbai)",
          db_engine: "Aurora PostgreSQL",
          instance_type: "db.r6i.32xlarge",
          pricing_type: "OnDemand",
          multi_az: "Single-AZ",
          usage_hours: 372.0,
          cost: 7856.64,
        },
        {
          linked_account_id: "244051943027",
          instance_name: "prod-zepto-db-aurora-reader-7",
          region: "Asia Pacific (Mumbai)",
          db_engine: "Aurora PostgreSQL",
          instance_type: "db.r6i.24xlarge",
          pricing_type: "Reserved",
          multi_az: "Single-AZ",
          usage_hours: 744.0,
          cost: 7749.503742874,
        },
      ],
    },
  },
};

export const graphTableLastHeader = (hidePrefix = false) => ({
  key: "total",
  value: "Total Cost",
  prefix: hidePrefix ? "" : `${getCurrencySymbol()}`,
  align: "Right",
});
