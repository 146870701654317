import React, { useState } from "react";
import ActionsDialog from "../../../../../../Common/Modal/ActionsDialog";
import SingleLevelTable from "../../../../CostExplorer/table";
import { sortHandler } from "../../../../CostBreakup/costbreakupUtils";
import "./style.scss";

function TableModal(props) {
  const {
    showModal,
    setShowModal,
    graphTableHeader,
    graphtableData,
    graphTableFooter,
    heading,
    downloadSheetButton,
  } = props;
  const [tableData, setTableData] = useState(graphtableData || []);
  const [ascending, setAscending] = useState(false);

  const sortHandlerParent = (sortBy) => {
    const updatedData = sortHandler(tableData, ascending, sortBy);
    setTableData([...updatedData]);
    setAscending(!ascending);
  };

  return (
    <ActionsDialog
      open={showModal}
      data={{
        config: {
          heading,
          type: "Custom",
          actions: false,
          dialogClass: "modal_table_container",
          body: (
            <>
              {downloadSheetButton()}
              <div className="table-wrapper rds-table">
                <div className="amazon_relation_table_wrapper">
                  <SingleLevelTable
                    headings={graphTableHeader}
                    data={tableData || []}
                    usageFooter={false}
                    footer={graphTableFooter}
                    newFooter={graphTableFooter}
                    v2
                    sortHandler={(sortBy) => sortHandlerParent(sortBy)}
                    sortedIcon={false}
                    GraphQuantity={false}
                    message="No Data Found"
                    currencyRequired
                  />
                </div>
              </div>
            </>
          ),
        },
      }}
      handleClose={() => setShowModal(false)}
      handleModalSubmit={() => setShowModal(false)}
      showCancelBtn={false}
      showCloseIcon
    />
  );
}

export default TableModal;
