import React, { useState, useEffect } from "react";
import { exportTop20CostUsageTableData } from "../../common/service";
import { getSelectedTab } from "../../../Database/util";
import { downloadExcel } from "../../../../../../../Utils/commonUtils";
import { sortHandler } from "../../../../CostBreakup/costbreakupUtils";
import ExcelDownload from "../../../../../../Common/ExcelDownload";
import Loader from "../../../../../../Common/Loader";
import SingleLevelTable from "../../../../CostExplorer/table";

function CudosTable(props) {
  const { selected, data, headers, className = "", footer } = props;
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState(data?.data?.data || {});
  const [ascending, setAscending] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const onExcelDownload = async () => {
    setExcelLoading(true);
    try {
      const res = await exportTop20CostUsageTableData(getSelectedTab(selected, []));
      downloadExcel(res);
    } finally {
      setExcelLoading(false);
    }
  };
  const sortHandlerParent = (sortBy) => {
    const updatedData = sortHandler(tableData?.data, ascending, sortBy);
    setTableData({ ...tableData, data: updatedData });
    setAscending(!ascending);
  };

  useEffect(() => {
    if (data?.data) {
      setTableData(data?.data?.data);
    }
    setLoader(false);
  }, [data]);

  return (
    <div className={`table-wrapper bg-[#fff] ${className}`}>
      <div className="flex items-center table-header-top border-b-[1px] border-[#D9D9D9] h-[42px] px-[12px] justify-between">
        <div className="text-[16px] text-[#2B2B2B] font-bold">
          Top 20 Node Instance Usage by Total Cost{" "}
        </div>
        <div className="ml-[16px]">
          <ExcelDownload
            downloadHandler={() => onExcelDownload()}
            hasData={!tableData?.data?.length}
            excelLoading={excelLoading}
          />
        </div>
      </div>
      <div className="amazon_relation_table_wrapper">
        {loader ? (
          <Loader />
        ) : (
          <SingleLevelTable
            headings={headers}
            data={tableData?.data || []}
            usageFooter={false}
            sortHandler={(sortBy) => sortHandlerParent(sortBy)}
            sortedIcon={false}
            GraphQuantity={false}
            message="No Data Found"
            currencyRequired
            footer={footer}
          />
        )}
      </div>
    </div>
  );
}

export default CudosTable;
