import React, { useEffect, useState } from "react";
import "./style.scss";
import { Tooltip } from "@mui/material";

export default function TabSelector(props) {
  const {
    list,
    imagesList = [],
    type,
    id,
    onSelectList,
    onSelect,
    selected,
    selectedValue,
    selectedIcon,
  } = props;

  const [selectedOption, setSelectedOption] = useState(selectedValue || list?.[0]);
  const [selectedColorOption, setSelectedColorOption] = useState(
    imagesList.length ? imagesList[0].text : ""
  );

  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  function SelectedTabhandler(tabSelected) {
    onSelectList(tabSelected);
    setSelectedOption(tabSelected);
  }

  function SelectedColorOptionHandler(tabSelected) {
    onSelect(tabSelected);
    setSelectedColorOption(tabSelected);
  }

  function renderSwitch() {
    switch (type) {
      case "text":
        return (
          <>
            {list.map((singleOption) => (
              <div
                // key={`text${index}`}
                className={` textTypetab tabSelector_option_wrapper ${
                  singleOption === selectedOption ? " selected" : ""
                }`}
                onClick={() => SelectedTabhandler(singleOption)}
                id={`${singleOption?.toUpperCase()}`}
                aria-hidden="true"
              >
                <p className="tabSelector_option">
                  {singleOption === selectedOption && selectedIcon && selectedIcon}
                  {singleOption}
                </p>
              </div>
            ))}
          </>
        );
      case "images":
        return (
          imagesList.length &&
          imagesList.map((singleOption) => (
            <Tooltip title={singleOption?.tooltipText} arrow placement="top">
              <div
                // key={`images${index}`}
                className={`${singleOption.text === selectedColorOption ? "selected" : ""}`}
                onClick={() => SelectedColorOptionHandler(singleOption.text)}
                aria-hidden="true"
              >
                {singleOption.imageUrl}
              </div>
            </Tooltip>
          ))
        );
      case "cudos-images":
        return (
          !!imagesList?.length &&
          imagesList.map((singleOption) => (
            <div
              // key={`cudos-images${index}`}
              className={`${singleOption.text === selected ? "selected" : ""}`}
              onClick={() => SelectedColorOptionHandler(singleOption.text)}
              aria-hidden="true"
            >
              {singleOption.imageUrl}
            </div>
          ))
        );
      default:
        return null;
    }
  }

  return (
    <div className="TabSelector_wrapper" id={`${id ? `tabSelector-${id}` : ""}`}>
      {renderSwitch()}
    </div>
  );
}
