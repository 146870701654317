import React, { Component } from "react";
import Graph from "../S3/graph/Graph";
import {
  getCurrencySymbol,
  stringToFixedNumber,
  formateStringTypeNumber,
} from "../../../../../Utils/commonUtils";
import TotalRiCoverageCost from "./TotalRiCoverageCost";
import { graphTableLastHeader } from "./constant";

class GraphWrapper extends Component {
  manipulateTableData = (data = {}) => {
    const tableData = data?.customTableDto?.tableRows.map((item) => {
      const tableObj = {};
      Object.keys(item.columnValuesForRow).forEach((internalItem) => {
        const value = item?.columnValuesForRow[internalItem];
        tableObj[internalItem] =
          typeof value?.cellValue === "number" ? stringToFixedNumber(value?.cellValue, 2) : value;
        return tableObj[internalItem] || "";
      });
      tableObj.rowName = item.rowName;
      tableObj.total =
        typeof item?.totalValueForRow === "number"
          ? stringToFixedNumber(item?.totalValueForRow, 2)
          : item?.totalValueForRow || "";
      return tableObj;
    });
    return tableData;
  };

  manipulateHeadersData = (data = [], graphConfig = {}) => {
    const { graphDataConstant } = this.props;
    const headers =
      data?.customTableDto?.columnNames?.map((item) => ({
        key: item,
        value: item,
        prefix: !(
          graphDataConstant[4]?.active !== "COST" && graphConfig?.name === "costByTop5Usage"
        )
          ? `${getCurrencySymbol()}`
          : "",
        align: item === "Unit" ? "Left" : "Right",
      })) || [];
    headers.unshift(graphConfig?.firstHeader);
    headers.push(
      graphTableLastHeader(
        graphDataConstant[4]?.active !== "COST" && graphConfig?.name === "costByTop5Usage"
      )
    );
    return headers;
  };

  manipulateFooterData = (data = [], graphConfig = {}) => {
    const { graphDataConstant } = this.props;
    const updatedTotal = {};
    const prefix = !(
      graphDataConstant[4]?.active !== "COST" && graphConfig?.name === "costByTop5Usage"
    )
      ? getCurrencySymbol()
      : "";
    Object.keys(data?.customTableDto?.columnWiseSum || {}).forEach((item) => {
      updatedTotal[item] = `${formateStringTypeNumber(
        data?.customTableDto?.columnWiseSum?.[item],
        !!prefix
      )}`;
    });
    const footer = { ...updatedTotal, rowName: "Total" };
    return footer;
  };

  manipulateGraphData = (data = []) => {
    const graphDataManipulationCategories = data?.categories?.map((item) => ({
      label: item,
    }));

    const graphDataManipulationData = data?.seriesList?.map((item) => {
      const dataArray = item.seriesValues.map((internalItem) => ({ value: internalItem }));
      const returnObj = {
        seriesname: item.seriesName,
        data: dataArray,
      };
      return returnObj;
    });

    const GraphObj = {
      category: graphDataManipulationCategories,
      dataset: graphDataManipulationData,
    };
    return GraphObj;
  };

  getGraphData = (item, index, graphDataValues) => {
    const graphData1 = this.manipulateGraphData(graphDataValues?.[index]?.data?.lineChart);
    const graphtableData = this.manipulateTableData(graphDataValues?.[index]?.data);
    const graphTableHeader = this.manipulateHeadersData(graphDataValues?.[index]?.data, item);
    const graphTableFooter = this.manipulateFooterData(graphDataValues?.[index]?.data, item);
    return {
      ...item,
      graphData: graphData1,
      graphtableData,
      graphTableHeader,
      graphTableFooter,
      showLabels: graphDataValues[index]?.showLabels || "0",
      trendsHeaderArray: graphDataValues[index]?.trendHeaderData,
      list: graphDataValues[index]?.list || item?.list || [],
      calendarType: item?.calendarType ? item?.calendarType : graphDataValues[index]?.calendarType,
    };
  };

  render() {
    const {
      props: {
        graphData,
        onExcelDownload,
        handleChangeGranularity,
        graphDataConstant,
        riCoverageData,
      },
    } = this;
    return (
      <div className="graph-wrapper flex flex-wrap gap-x-[16px] gap-y-[16px]">
        {graphData.length ? (
          <>
            {graphDataConstant.map((item, index) => {
              const graph = this.getGraphData(item, index, graphData);
              return (
                <Graph
                  graph={graph}
                  onGraphChange={(name, selectedProp, type) =>
                    handleChangeGranularity(name, selectedProp, type)
                  }
                  id={item.name}
                  key={item.name}
                  show={item.show}
                  showExcelBtn
                  showAxisIcons={{
                    xAxis: true,
                    yAxis: true,
                  }}
                  wrapperClass={item.wrapperClass}
                  onExcelDownload={onExcelDownload}
                  graphLoading={item?.loading}
                  excelLoading={item?.excelLoading}
                  showSortingOnTotal
                />
              );
            })}
            {riCoverageData && <TotalRiCoverageCost data={riCoverageData} />}
          </>
        ) : null}
      </div>
    );
  }
}

export default GraphWrapper;
