import React from "react";
import "./style.scss";

function InfoBar(props) {
  const { barText, isChartLoading } = props;

  return (
    <div className="info_bar_container">
      {!isChartLoading && (
        <div className="info_bar_wrapper">
          <p>{barText}</p>
        </div>
      )}
    </div>
  );
}

export default InfoBar;
