import React, { useState } from "react";
import { ReactComponent as LeftSliderIcon } from "../../Assets/left-slider.svg";
import { ReactComponent as RightSliderIcon } from "../../Assets/right-slider.svg";
import { DAILY_MONTHLY_TOGGLE, IMAGES_LIST } from "../../common/constant";
import { noop } from "../../../../../../../Utils/commonUtils";
import "./style.scss";

function ChartType(props) {
  const {
    onGranulityChange = noop,
    onChartTypeChange = noop,
    activeChartType = "",
    activeGranulity = "",
  } = props;
  const [showGranulityContainer, setShowGranulityContainer] = useState(false);
  return (
    <div
      className="ck-cudos-slider_container"
      onMouseEnter={() => setShowGranulityContainer(true)}
      onMouseLeave={() => setShowGranulityContainer(false)}
      data-testid="granulity-container"
    >
      {showGranulityContainer ? (
        <RightSliderIcon className="toggle-icon-right" />
      ) : (
        <LeftSliderIcon className="toggle-icon-left" />
      )}

      <div className="granulity_container">
        <div className="ck-cudos-granualarity-heading">
          <p>Granularity</p>
        </div>
        {DAILY_MONTHLY_TOGGLE.map((_) => (
          <div
            role="button"
            data-testid={`granulity-${_.label}`}
            className={`ck-cudos-granualarity-value ${_.label === activeGranulity ? "active" : ""}`}
            tabIndex={0}
            aria-hidden
            onClick={() => onGranulityChange(_.label)}
          >
            {_.label === activeGranulity ? _.activeIcon : _.inactiveIcon}
            {_.label}
          </div>
        ))}
        <div className="ck-cudos-granualarity-heading border-t border-solid border-t-[#c7dcee] border-[#c7dcee] pt-[6px]">
          <p>Graph View</p>
        </div>
        {IMAGES_LIST.map((_) => (
          <div
            role="button"
            data-testid={`chart-type-${_.text}`}
            tabIndex={0}
            onClick={() => onChartTypeChange(_.text)}
            aria-hidden
            className={`ck-cudos-granualarity-value ${_.text === activeChartType ? "active" : ""}`}
          >
            {_.text === activeChartType ? _.activeIcon : _.inactiveIcon}
            {_.label}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChartType;
