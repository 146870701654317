const getPayload = ({
  formattedDate,
  affectedHostUrl,
  formattedErrorInfo,
  currentUserInformation,
  error,
}) => ({
  color: "#FF0000",
  blocks: [
    {
      key: "[Error Caught]",
      value: `_*@timestamp: ${formattedDate}*_`,
    },
    {
      key: "[User Information]",
      value: `\n\`\`\`${currentUserInformation}\`\`\`\n`,
    },
    {
      key: "[Affected Host URL]",
      value: `${affectedHostUrl}`,
    },
    {
      key: "[Error Message]",
      value: `${error} - Error Code - ${error?.code || error.name}`,
    },
    {
      key: "[Error Information]",
      value: `\n\`\`\`${formattedErrorInfo}\`\`\`\n`,
    },
  ],
});

export default getPayload;
