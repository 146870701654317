import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PERMISSIONS_ENUM } from "../../../../../Constants/permissions";
import withRouter from "../../../../Common/WithRouter";
import AccessDeniedMessage from "../../../../Common/AccessDeniedMessage";
import CudosHeader from "../CudosHeader";
import useEKS from "./hooks/useEKS";
import Loader from "../../../../Common/Loader";
import Cards from "./component/MonthCards";
import Routes from "../../../../../Config/Routes";
import GraphWrapper from "../Database/GraphWrapper";
import SummaryCards from "./component/SummaryCards";
import ChartType from "./component/ChartType";
import CudosTable from "./component/Table";
import { TOP_20_INSTANCE_HEADERS } from "./common/constant";
import "./style.scss";
import { getFooterData, getRouteUrlWithoutSelected } from "./common/helper";
import RecommendationSummary from "./component/RecommendationSummary";

function EKS(props) {
  const { hasPermission, pathArray, setPermission, accessList, documentTitle } = props;

  const accountDate = useSelector((state) => state.AccountDateReducer) || {};
  const { accountEndDate, accountStartDate } = accountDate;
  const {
    isLoading,
    isGranularityLoading,
    selectedDate,
    cardsData,
    summaryCards,
    graphData,
    graphDataConstant,
    onGranulityChange,
    activeGranulity,
    onChartTypeChange,
    activeChartType,
    instanceTableData,
    onExcelDownload,
    recommendationSummary,
  } = useEKS();
  const navigate = useNavigate();

  useEffect(() => {
    setPermission(accessList);
    getRouteUrlWithoutSelected(navigate, accountDate, Routes.COST_BREAKUP_EKS);
  }, []);

  return hasPermission(PERMISSIONS_ENUM.READ) ? (
    <div className="ck-eks-wrapper">
      <CudosHeader
        breadcrumsData={pathArray.map((item) => item.label)}
        headerName={documentTitle}
        selectedDateProp={selectedDate}
        accountEndDate={accountEndDate}
        accountStartDate={accountStartDate}
        isLoading={isLoading}
      />
      <ChartType
        onGranulityChange={onGranulityChange}
        onChartTypeChange={onChartTypeChange}
        activeChartType={activeChartType}
        activeGranulity={activeGranulity}
      />
      {!isLoading && (
        <div className="flex gap-[5px]">
          <Cards cardsData={cardsData} />
          <SummaryCards summaryCardsData={summaryCards} />
          <RecommendationSummary data={recommendationSummary} />
        </div>
      )}
      <div className={`graph-container ${isGranularityLoading ? "h-[500px]" : ""}`}>
        {isLoading || isGranularityLoading ? (
          <Loader />
        ) : (
          <GraphWrapper
            graphData={graphData}
            onExcelDownload={onExcelDownload}
            graphDataConstant={graphDataConstant}
          />
        )}
      </div>
      {graphData?.length && !isLoading ? (
        <CudosTable
          data={instanceTableData}
          headers={TOP_20_INSTANCE_HEADERS}
          className="rds-table"
          footer={getFooterData(instanceTableData, TOP_20_INSTANCE_HEADERS)}
        />
      ) : null}
    </div>
  ) : (
    <AccessDeniedMessage />
  );
}

export default withRouter(EKS);
