const numberWithCommasandDecimalAndDollar = (x = 0, type = "number") => {
  if (typeof x === "number") {
    switch (type) {
      case "number":
        if (x < 0 && x > -1) {
          const withoutNegative = x * -1;
          if (x.toFixed(2) === "-0.00") {
            return `$${withoutNegative
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          }
          return `-$${withoutNegative
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        if (x < -1) {
          const withoutNegative = x * -1;
          return `-$${withoutNegative
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        if (x >= 0 && x < 1) {
          return `$${x.toFixed(2)}`;
        }
        return `$${x
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      default:
        return x;
    }
  } else {
    return x;
  }
};

export default numberWithCommasandDecimalAndDollar;
