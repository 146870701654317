import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { ReactComponent as DeleteModalIcon } from "../../../../Assets/icons/delete-modal.svg";
import { ReactComponent as Informative } from "../../../../Assets/icons/informative.svg";
import { MODAL_TYPE } from "../../../CK-Lens/Constants/constant";
import Button from "../../../FormConfig/Form/Components/Button";
import { ReactComponent as CloseIcons } from "../../../../Assets/icons/close-model-icon.svg";
import { noop } from "../../../../Utils/commonUtils";

import "./style.scss";

export default function ActionsDialog({
  open,
  data: {
    config: {
      heading = "",
      body = "",
      type,
      primaryBtnText = "Ok",
      actions = true,
      icon,
      className,
      dialogClass,
      description = "",
    } = {},
    values,
  },
  handleClose,
  handleModalSubmit = noop,
  disabledSubmitBtn = false,
  V2 = false,
  showCancelBtn = true,
  showHeadingOnbottom = false,
  showCloseIcon = false,
}) {
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
  }));

  const classes = useStyles();

  const renderIcon = () => {
    switch (type) {
      case MODAL_TYPE.INFORMATIVE:
        return <Informative />;
      case MODAL_TYPE.DELETE:
        return <DeleteModalIcon />;
      case MODAL_TYPE.CUSTOM:
        return icon;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={`${V2 ? "V2-container" : ""} ${dialogClass || ""}`}
      aria-labelledby={`${(V2 && "V2-container") || "alert-delete-dialog"} ${dialogClass}`}
      id="delete_popup"
      maxWidth="xs"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      {!showHeadingOnbottom && (
        <DialogTitle className="delete-dialog-title" id="alert-dialog-title">
          {heading}
          {showCloseIcon && (
            <CloseIcons onClick={handleClose} className="absolute right-[12px] cursor-pointer " />
          )}
        </DialogTitle>
      )}
      <DialogContent className="delete-dialog-body">
        <DialogContentText id="alert-dialog-description">
          {renderIcon()}
          {showHeadingOnbottom && (
            <DialogTitle className="delete-dialog-title" id="alert-dialog-title">
              {heading}
            </DialogTitle>
          )}
          {body}
          {description && <span className="text-[14px] font-[400]">{description}</span>}
        </DialogContentText>
      </DialogContent>
      {actions && (
        <DialogActions className={`delete-dialog-footer ${className || ""}`}>
          {showCancelBtn && (
            <Button
              className="rounded-[3px] text-xs bg-white "
              size="small"
              variant="outline-primary-nohover"
              handleClick={() => handleClose({ cancelBtn: true })}
              text="Cancel"
            />
          )}
          <Button
            className="rounded-[3px] text-xs !font-medium primary-hover"
            size="small"
            variant="primary"
            id="submit"
            text={primaryBtnText}
            handleClick={() => {
              handleClose();
              handleModalSubmit(values);
            }}
            type="submit"
            disabled={disabledSubmitBtn}
          />
        </DialogActions>
      )}
    </Dialog>
  );
}
