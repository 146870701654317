import React from "react";
import { SUMMARY_CARDS_IMAGES } from "../../common/constant";
import "./style.scss";

function SummaryCards(props) {
  const { summaryCardsData = [] } = props;
  return (
    <div className="ck-cudos-summary-cards-wrapper">
      {summaryCardsData.map((data, index) =>
        SUMMARY_CARDS_IMAGES[index]?.backgroundImage !== "" ? (
          <div
            style={{
              backgroundImage: `url(${SUMMARY_CARDS_IMAGES[index].backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="ck-cudos-summary-card"
          >
            {data?.value?.map((ele) => (
              <div className="m-[8px] mt-[12px]">
                {SUMMARY_CARDS_IMAGES?.[index]?.icon}
                <div className="text-[12px] font-semibold text-[#0C477E] mt-[8px] mb-[0px]">
                  {ele.key}
                </div>
                <div className="text-[18px] font-normal text-[#000000]">{ele?.value || "--"}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-used-unused-view">
            <div className="card-label">{data?.key}</div>
            <div className="flex mx-[8px] mt-[14px] mb-[13px]">
              {data?.value?.map((ele, i) => (
                <div className={`used w-1/${data?.value?.length}`}>
                  <div
                    className={`inline-block text-[12px] mb-[8px] rounded-[2px] px-[5px] ${
                      SUMMARY_CARDS_IMAGES?.[index]?.colorClasses?.[i] || ""
                    }`}
                  >
                    {ele?.key}
                  </div>
                  <div className="text-[15px]">{ele?.value || "--"}</div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default SummaryCards;
