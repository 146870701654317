import { getTotalAccountsPayload } from "../../../CostBreakup/costbreakupUtils";
import commonService from "../../../../../../Services/commonService";
import { manipulateApiResponse } from "../../Database/util";
import { SELECTED_TAB } from "./constant";

export const getSummaryCardsData = async (selected) => {
  try {
    const res = await commonService.getSummaryCards(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    return res?.data?.data || [];
  } catch (error) {
    return error;
  }
};

export const getCostByInstanceType = async (reportGranularity = "MONTHLY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      service: SELECTED_TAB,
      reportGranularity,
    });
    const instanceTypeData = await commonService.getCostByInstanceTypeDatabase(graphPayload);
    const data = manipulateApiResponse(instanceTypeData)?.data?.data;
    return {
      name: "costByInstanceType",
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByCluster = async (reportGranularity = "MONTHLY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: SELECTED_TAB,
    });
    const costByAccountsData = await commonService.getCostCluster(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name: "costByClusters",
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByNameSpace = async (reportGranularity = "MONTHLY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: SELECTED_TAB,
    });
    const costByAccountsData = await commonService.getCostByNameSpace(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name: "costByNamespace",
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByPurchase = async (reportGranularity = "MONTHLY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: SELECTED_TAB,
    });
    const costByAccountsData = await commonService.getCostByPurchase(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name: "costByPurchaseType",
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getTop20InstanceTableData = async (selected) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
    });
    const res = await commonService.getTop20CostUsage(payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const exportCostByCluster = async (selected, reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByCluster(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByNamespace = async (selected, reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByNamespace(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByRegion = async (selected, reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByRegion(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByInstanceType = async (selected, reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByInstanceType(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByPurchaseType = async (selected, reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByPurchaseTypeEKS(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportTop20CostUsageTableData = async () => {
  try {
    const res = await commonService.exportTop20CostUsageTableData(
      getTotalAccountsPayload({ service: SELECTED_TAB })
    );
    return res;
  } catch (error) {
    return error;
  }
};
