/* eslint-disable complexity */
import { TABLE_ICONS_PERMISSIONS } from "../../../../Constants/permissions";
import { subscribe } from "../../../../Utils/events";
import { applyFilters } from "../../../../Utils/filterUtils";
import { filterArrayOfObjects, getHeaders } from "../../../Dashboard/ConfigDiscounts/util";
import {
  returnAddCartCase,
  returnAddCase,
  returnAgreementCase,
  returnApplyCase,
  returnCalenderCase,
  returnCancelCase,
  returnChangePlanCase,
  returnCloneCase,
  returnCopyCase,
  returnDateTimeCase,
  returnDeleteCase,
  returnEditCase,
  returnEditRevampCase,
  returnEndCase,
  returnInputArrowCase,
  returnInputCase,
  returnPreviewCase,
  returnPromoteCase,
  returnPromoteRevampCase,
  returnRefreshCase,
  returnResendVerification,
  returnResendVerificationRevampCase,
  returnSaveCase,
  returnToggleCase,
} from "../utils";

export const bindEvent = (config, dataCopy, filtersCopy, filterSelected, setData) => {
  subscribe("filter-applied", (e) => {
    const updatedData = applyFilters(dataCopy, filtersCopy, filterSelected);
    const filteredDataUpdated = filterArrayOfObjects(
      updatedData.filteredData,
      e.detail.searchTerm,
      getHeaders(config.headers)
    );
    setData([...filteredDataUpdated]);
  });
};

export const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    padding: "4px",
    fontSize: "9px",
    backgroundColor: "grey",
  },
  "& .MuiTooltip-arrow": {
    "&:before": {
      backgroundColor: "grey",
    },
  },
};

export const getIconsDefaultCase = (icon, tableBody, index, onIconClick) => {
  let component = null;
  switch (icon.actionType) {
    case "changePlan":
      component = returnChangePlanCase(icon, tooltipTop, tableBody);
      break;
    case "showAgreement":
      component = returnAgreementCase(icon, tooltipTop, tableBody, onIconClick);
      break;
    case "refresh":
      component = returnRefreshCase(tableBody, icon, onIconClick);
      break;
    case "editRevamp":
      component = returnEditRevampCase(icon, tooltipTop, tableBody, index, onIconClick);
      break;
    case "promoteRevamp":
      component = returnPromoteRevampCase(icon, tableBody, onIconClick, tooltipTop);
      break;
    case "resendVerificationRevamp":
      component = returnResendVerificationRevampCase(tableBody, icon, onIconClick, tooltipTop);
      break;
    case "apply":
      component = returnApplyCase(tableBody, icon, onIconClick, tooltipTop);
      break;
    default:
      break;
  }
  return component;
};

export const getIcons = (
  icon,
  tableBody,
  index,
  permissions,
  hasWritePermission,
  { onIconClick, onInputChange, onAddCart },
  { min, minDate, maxDate }
) => {
  let component = null;
  if (!permissions[TABLE_ICONS_PERMISSIONS[icon?.actionType]]) {
    return component;
  }
  switch (icon.actionType) {
    case "toggle":
      component = returnToggleCase(icon, hasWritePermission, tooltipTop, tableBody, onIconClick);
      break;
    case "delete":
      component = returnDeleteCase(icon, tooltipTop, tableBody, onIconClick, index);
      break;
    case "edit":
      component = returnEditCase(icon, tooltipTop, tableBody, index, onIconClick);
      break;
    case "cancel":
      component = returnCancelCase(icon, tooltipTop, tableBody, onIconClick);
      break;
    case "save":
      component = returnSaveCase(icon, tooltipTop, tableBody, onIconClick);
      break;
    case "add":
      component = returnAddCase(icon, tooltipTop, tableBody, onIconClick);
      break;
    case "end":
      component = returnEndCase(icon, tooltipTop, tableBody, onIconClick);
      break;
    case "promote":
      component = returnPromoteCase(icon, tableBody, onIconClick);
      break;
    case "resendVerification":
      component = returnResendVerification(tableBody, icon, onIconClick, tooltipTop);
      break;
    case "input":
      component = returnInputCase(onInputChange, tableBody, min, true, icon);
      break;
    case "inputArrow":
      component = returnInputArrowCase(onInputChange, tableBody, min, true, icon);
      break;
    case "addCart":
      component = returnAddCartCase(icon, tableBody, onInputChange, onAddCart);
      break;
    case "clone":
      component = returnCloneCase(tableBody, tooltipTop, icon, onIconClick);
      break;
    case "preview":
      component = returnPreviewCase(tableBody, tooltipTop, icon, onIconClick);
      break;
    case "calender":
      component = returnCalenderCase(tableBody, onIconClick, icon, minDate);
      break;
    case "date_time_calender":
      component = returnDateTimeCase(tableBody, onIconClick, icon, minDate, maxDate);
      break;
    case "copy":
      component = returnCopyCase(tableBody, tooltipTop, onIconClick, icon);
      break;
    default:
      component = getIconsDefaultCase(icon, tableBody, index, onIconClick);
      break;
  }
  return component;
};
