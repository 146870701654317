import commonService from "../../../../../Services/commonService";
import { getPayload, getTotalAccountsPayload } from "../../CostBreakup/costbreakupUtils";
import { DAILY_MONTHLY_TOGGLE, RICoverageCost } from "./constant";
import { getCalenderType, getDownloadCardData, manipulateApiResponse } from "./util";

export const getTotalAccountsData = async (selected, reportGranularity = "DAILY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getTotalAccountsData(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name: "costByAccounts",
      data,
      list: DAILY_MONTHLY_TOGGLE,
      showLabels: "1",
      calendarType: getCalenderType(30),
    };
  } catch (error) {
    return error;
  }
};

export const getCostByTop5Usage = async (selected, type = "COST") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      type,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostByTop5Usage(graphPayload);
    const data = manipulateApiResponse(costByAccountsData, type !== "COST")?.data?.data;
    return {
      name: "costByTop5Usage",
      data,
      calendarType: getCalenderType(90),
    };
  } catch (error) {
    return error;
  }
};

export const getCostRegion = async (selected, reportGranularity = null) => {
  try {
    const regionData = await commonService.getCostRegion(
      getTotalAccountsPayload({ service: selected?.toUpperCase(), reportGranularity })
    );
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name: "costByRegions",
      data,
      trendHeaderData: [],
      showLabels: "1",
      calendarType: getCalenderType(90),
    };
  } catch (error) {
    return error;
  }
};

export const getCostEngine = async (selected) => {
  try {
    const engineData = await commonService.getCostEngine(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    const data = manipulateApiResponse(engineData)?.data?.data;
    return {
      name: "costByEngines",
      data,
      trendHeaderData: [],
      showLabels: "1",
      calendarType: getCalenderType(90),
    };
  } catch (error) {
    return error;
  }
};

export const getCostByInstanceTypeDatabase = async (selected, reportGranularity = "DAILY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const instanceTypeData = await commonService.getCostByInstanceTypeDatabase(graphPayload);
    const data = manipulateApiResponse(instanceTypeData)?.data?.data;
    return {
      name: "costByInstanceType",
      data,
      list: DAILY_MONTHLY_TOGGLE,
      showLabels: "1",
      calendarType: reportGranularity === "DAILY" ? getCalenderType(30) : getCalenderType(180),
    };
  } catch (error) {
    return error;
  }
};

export const getCudosDatabaseCards = async (
  selected,
  manipulateCardsData = getDownloadCardData
) => {
  try {
    const payload = getPayload(selected?.toUpperCase());
    const cudosCardsData = await commonService.getCudosDatabaseCards(payload);
    return manipulateCardsData(cudosCardsData?.data?.data);
  } catch (error) {
    return error;
  }
};

export const getTop20InstanceTableData = async (selected, limit) => {
  try {
    const payload = getTotalAccountsPayload({
      limit,
      service: selected?.toUpperCase(),
    });
    const res = await commonService.getTop20CostUsage(payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const getRICoverageData = async (selected) => {
  try {
    const res = await commonService.getRiCoverageUsage(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    return {
      stackedGraphData: res?.data?.data?.coverageList,
      graphData: {
        ...RICoverageCost,
        graphData: {
          data: [
            {
              label: "Reservation Covered Cost",
              value: res?.data?.data?.totalRdsReservationCost,
            },
            {
              label: "Remaining OnDemand Cost",
              value: res?.data?.data?.totalRdsOnDemandCost,
            },
          ],
        },
      },
      riCoverageData: res?.data?.data,
    };
  } catch (error) {
    return error;
  }
};

export const exportTop20CostUsageTableData = async (selected) => {
  try {
    const res = await commonService.exportTop20CostUsage(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const exportCostByAccount = async (selected, reportGranularity = "DAILY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByAccount(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByUsage = async (selected, reportGranularity = "DAILY") => {
  const graphPayload = getTotalAccountsPayload({
    type: reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByUsage(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByRegion = async (selected) => {
  const graphPayload = getTotalAccountsPayload({ service: selected?.toUpperCase() });
  try {
    const res = await commonService.exportTotalCostByRegion(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByEngine = async (selected) => {
  const graphPayload = getTotalAccountsPayload({ service: selected?.toUpperCase() });
  try {
    const res = await commonService.exportTotalCostByEngine(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByInstance = async (selected, reportGranularity = "DAILY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByInstanceType(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};
