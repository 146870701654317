import Routes from "../Config/Routes";
import AddIcon from "../Assets/icons/add-icon.svg";
import { getUserAddEditRoutesFromRole } from "../Components/Dashboard/UserManagement/CreateUser/util";
import { noop } from "../Utils/commonUtils";

// eslint-disable-next-line import/prefer-default-export
export const CONTACT_US = "mailto:support@cloudkeeper.com";
export const AzureContactUs = "support@cloudkeeper.com";
export const link = window.location.pathname.includes(Routes.PREFERENCES)
  ? Routes.PREFERENCES_USER_ADD
  : Routes.ADDUSER;

export const isPreferences = () => !!window.location.pathname.includes(Routes.PREFERENCES);

export const userManagementBtnList = (handleClick) => [
  {
    label: "Add New User",
    class: "dividerRight rounded-[3px] leading-none",
    variant: "primary",
    link: isPreferences() ? "" : getUserAddEditRoutesFromRole().addUserRoute,
    image: { className: "mr-[10px]", src: AddIcon, alt: "add icon" },
    handleClick: isPreferences() ? handleClick : noop(),
  },
];

export const discountTemplateBtnList = (customerName) => [
  {
    label: "Create Template",
    class: "dividerRight rounded-[3px] leading-none",
    variant: "primary",
    link: `${Routes.EDIT_DISCOUNT_TEMPLATES}?customer=${customerName}`,
    image: { className: "mr-[5px]" },
  },
];

export const PARTNER_MANAGEMENT = [
  {
    label: "Add New Partner",
    class: "dividerRight rounded-[3px] leading-none",
    variant: "primary",
    link: Routes.ADDPARTNER,
    image: { className: "mr-[5px]", src: AddIcon, alt: "add icon" },
  },
];

export const PAYER_ACCOUNTS = [
  {
    label: "Add New Payer",
    class: "dividerRight rounded-[3px] leading-none",
    variant: "primary",
    link: Routes.ADDPAYER,
    image: { className: "mr-[5px]", src: AddIcon, alt: "add icon" },
  },
];

export const CUSTOMER_MANAGEMENT = (isAddCustomerButton) => [
  {
    label: "Add New Customer",
    class: `dividerRight rounded-[3px] leading-none ${!isAddCustomerButton && "hidden"}`,
    variant: "primary",
    link: Routes.ADDCUSTOMER,
    image: { className: "mr-[5px]", src: AddIcon, alt: "add icon" },
  },
];

export const INVOICE_SETTING = [
  {
    label: "Add New Setting",
    class: "dividerRight rounded-[3px] leading-none",
    variant: "primary",
    link: Routes.INVOICESETTINGS,
    image: { className: "mr-[5px]", src: AddIcon, alt: "add icon" },
  },
];

export const SERVER_ERROR = "Something went wrong. Please try again later.";

export const TEMPLATE_TYPE = [
  { label: "Generic", value: "GENERIC_DISCOUNT" },
  { label: "Enterprise Discount Program (EDP)", value: "EDP_DISCOUNT" },
  { label: "Reservation Discount", value: "RESERVATION_TYPE" },
];

export const BUDGET_ALERT_TYPE = [
  { label: "Daily", value: "DAILY" },
  { label: "Monthly", value: "MONTHLY" },
];

export const BTN_ARRAY_MODAL = [
  { label: "Cancel", class: "cancel button outline-primary medium ml-[14px] rounded" },
  { label: "Apply Rate", class: "apply-rate button primary medium ml-[14px] rounded" },
];

export const constructOrderingTemplatesBtnList = (onClick) => [
  {
    label: "Create Template",
    class: "create_template button primary medium rounded",
    variant: "primary",
    handleClick: () => onClick("createTemplate"),
    // link: `${Routes.EDIT_DISCOUNT_TEMPLATES}?customer=${customerName}`,
    // image: { className: "mr-[5px]" },
  },
  {
    label: "Make Default",
    class: "make_default button outline-primary medium rounded",
    variant: "outlined",
    handleClick: () => onClick("makeDefault"),
    // link: `${Routes.EDIT_DISCOUNT_TEMPLATES}?customer=${customerName}`,
    // image: { className: "mr-[5px]" },
  },
];

export const PERMSISSION_GROUPS = [
  {
    label: "Create New Permission Group",
    class: "dividerRight rounded-[3px] leading-none",
    variant: "primary",
    link: "create",
    image: { className: "mr-[5px]", src: AddIcon, alt: "add icon" },
  },
];
