import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../WithRouter";
import { ReactComponent as HomeOutlinedIcon } from "../../../Assets/icons/others/iconHomeV2.svg";
import SeeMoreText from "../SeeMoreText";

function Breadcrumb({ crumbs, newThemeBreadcrumb, showMore = false, className }) {
  const getLabel = (item) =>
    showMore ? (
      <p className="small-text-breadcrum ">
        <SeeMoreText text={item?.label?.replace("%20", " ")} component="Breadcrumbs" />
      </p>
    ) : (
      item?.label
    );

  const handleNavigation = (item) => {
    if (item.isRefreshable) {
      window.location.href = item.path;
    }
  };

  const getCrumb = () =>
    !!crumbs &&
    crumbs?.map((item) => (
      <div
        className={`crumb-wrapper ${
          item?.label?.toLowerCase() === "home" && !newThemeBreadcrumb ? "crubmsHomeIcon" : ""
        }`}
      >
        <Link
          replace
          to={{
            pathname: item.path,
            search: item?.queryParam,
          }}
          onClick={() => handleNavigation(item)}
          state={item?.state}
          className={`crumb ${!item?.path && "disabled"}`}
          key={`${item?.label}-${Math.random()}-${item?.label}`}
        >
          {item?.label?.toLowerCase() === "home" && !newThemeBreadcrumb ? (
            <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          ) : (
            getLabel(item)
          )}
        </Link>
      </div>
    ));

  return (
    <p
      className={`${className} breadcrumb-wrapper mb-1 ${
        newThemeBreadcrumb ? "newTheme-breadcrumb" : ""
      }`}
    >
      {getCrumb()}
    </p>
  );
}

export default withRouter(Breadcrumb);
