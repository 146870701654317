import React from "react";
import { useNavigate } from "react-router";
import { numberWithCommasandDecimal } from "../../../../../../../Utils/commonUtils";
import { ReactComponent as Arrow } from "../../Assets/arrow.svg";
import CardImage from "../../Assets/eks-recommendation.svg";

function RecommendationSummary(props) {
  const { data } = props;
  const navigate = useNavigate();

  return (
    <div
      className="px-[9px] py-[5px] bg-[white] mx-[0] my-[5px] rounded-[5px] mr-[12px]"
      style={{
        backgroundImage: `url(${CardImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-[14px] font-semibold leading-[15px] mt-[4px]">
        You can reduce monthly cost
      </div>
      <div className="text-[11px] font-normal leading-[15px] text-[#2B2B2B] mt-[2px] mb-[5px]">
        By migrating to the latest generation instance types
      </div>
      <div className="text-[16px] font-semibold leading-[19px] text-[#2B2B2B] flex items-end">
        {numberWithCommasandDecimal(data.savings)}
        <Arrow
          onClick={() => {
            navigate(`/dashboard${data?.link}`);
          }}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
}

export default RecommendationSummary;
