import React from "react";
import moment from "moment";
import { ReactComponent as InfoIcon } from "../../../Assets/images/ic-info.svg";
import { getCurrencySymbol } from "../../../Utils/commonUtils";
import "./style.scss";

export const numberWithCommasandDecimal = (x = 0, toFixed = 2 /* , row = {} */) => {
  if (typeof x === "number") {
    if (x < 0) {
      const withoutNegative = x * -1;
      // eslint-disable-next-line eqeqeq
      if (x.toFixed(toFixed) == 0.0) {
        return `${getCurrencySymbol()}${withoutNegative
          .toFixed(toFixed)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      return `-${getCurrencySymbol()}${withoutNegative
        .toFixed(toFixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    return `${getCurrencySymbol()}${x
      .toFixed(toFixed)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return x;
};

export default function EDPDiscountTypeTooltip(item, key = "") {
  const data = item;
  return (data?.discountType === "EDP Discount" ||
    data?.discountType === "CloudFront Private Pricing") &&
    data?.commitments?.length > 0 ? (
    <div
      className={`specific-table-item-tooltip ${data?.termLevelSummary ? "" : "blue-info_icon"}`}
    >
      <span>{data?.termLevelSummary}</span>
      <div>
        <InfoIcon />
        <div className="tooltip-box">
          <table>
            {key === "EDP" ? (
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Commitments</th>
                  <th>Discount</th>
                  <th>Credits</th>
                </tr>
              </thead>
            ) : (
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Data Transfer</th>
                  <th>Data Transfer Unit Type</th>
                </tr>
              </thead>
            )}
            <tbody>
              {key === "EDP"
                ? data?.commitments?.map((commitment) => (
                    <tr key="">
                      <td>{commitment?.tenure}</td>
                      <td>{numberWithCommasandDecimal(Number(commitment?.commitment), 0)}</td>
                      <td>{`${commitment?.discount}%`}</td>
                      <td>{numberWithCommasandDecimal(Number(commitment?.credits), 0)}</td>
                    </tr>
                  ))
                : data?.commitments?.map((commitment) => (
                    <tr key="">
                      <td>{commitment?.tenure}</td>
                      {/* <td>{`${commitment?.qty}TB`}</td> */}
                      <td>{commitment?.qty}</td>
                      <td>{commitment?.unit}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <div>{data?.discountType}</div>
  );
}

export const dicountToolip = (item) =>
  item?.discountType === "EDP Discount" && item?.commitments?.length > 0 ? (
    <div className="specific-table-item-tooltip">
      <span>{item?.commitments?.[0]?.discount}</span>
      <div>
        <InfoIcon />
        <div className="tooltip-box">
          <table>
            <thead>
              <tr>
                <th>Tenure</th>
                <th>Discount(%)</th>
              </tr>
            </thead>
            <tbody>
              {item?.commitments?.map((commitment) => (
                <tr key="">
                  <td>{commitment?.tenure}</td>
                  <td>{commitment?.discount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <div>{item?.discountPercentage}</div>
  );

export function CustomerStatusHistoryTooltip(props) {
  const { customerTypeHistory } = props;
  return (
    <div className="specific-table-item-tooltip-status-history">
      <table className="TermLevelSummary_tooltip_style">
        <thead className="TermLevelSummary_tooltip_tableHead">
          <tr className="TermLevelSummary_tooltip_tableRow">
            <th className="TermLevelSummary_tooltip_tableData">Customer Type</th>
            <th className="TermLevelSummary_tooltip_tableData">Start Date</th>
            <th className="TermLevelSummary_tooltip_tableData">End Date</th>
          </tr>
        </thead>

        <tbody>
          {customerTypeHistory?.map((item) => (
            <tr key={item?.id}>
              <td className="TermLevelSummary_tooltip_tableRow !bg-[#F0F6FF]">{item?.type}</td>
              <td className="TermLevelSummary_tooltip_tableRow !bg-[#F0F6FF]">
                {item?.startDate ? moment(item?.startDate).format("DD-MMM-YY") : "---"}
              </td>
              <td className="TermLevelSummary_tooltip_tableRow !bg-[#F0F6FF]">
                {item?.endDate ? moment(item?.endDate).format("DD-MMM-YY") : "---"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export const descriptionTooltip = (item, key = "") =>
  item?.[key] && (
    <div className="specific-table-item-tooltip">
      {/* {item?.name && <span>{item?.name}</span>} */}
      {item?.[key] && <span>{item?.[key]}</span>}
      {item.description && (
        <div>
          <InfoIcon />
          <div className="tooltip-box">
            <p className="tooltip-heading">{`${
              key === "templateApplied" ? "Template" : "Discount"
            } Description`}</p>
            <p className="tooltip-content">{item.description}</p>
          </div>
        </div>
      )}
    </div>
  );

export const addPercentageToFlatDiscount = (item) => {
  if (item?.discountApplication?.toLowerCase() === "flat") {
    return <div>{`${item?.discountApplicationDetail}%`}</div>;
  }
  return <div>{item?.discountApplicationDetail || "- - -"}</div>;
};

export const isArrayOfObjects = (myArray) =>
  Array.isArray(myArray) && myArray.length > 0 && typeof myArray[0] === "object";

export const filterArrayOfObjects = (arr, searchTerm = "", headers = []) => {
  const searchValue = searchTerm?.toLowerCase().trim();

  return arr.filter((obj) =>
    Object?.keys(obj)?.some((key) => {
      if (key !== "actions" && headers?.includes(key)) {
        if (typeof obj?.[key] === "string") {
          return obj?.[key]?.toLowerCase()?.includes(searchValue);
        }
        if (Array?.isArray?.(obj?.[key])) {
          let searchArr = obj?.[key];
          if (isArrayOfObjects(obj?.[key])) {
            searchArr = obj?.[key].map((item) => ({ ...item, checkboxId: undefined }));
          }
          return JSON.stringify(searchArr)?.toLowerCase()?.includes(searchValue);
        }
      }
      return false;
    })
  );
};

export const getSearchHeaders = (headers) => {
  const searchHeaders = [];
  const filteredHeaders = headers.filter((item) => item?.searchable) || [];
  filteredHeaders.forEach((header) => {
    if (header?.searchableKeys) {
      searchHeaders.push(...header.searchableKeys);
    } else {
      searchHeaders.push(header?.accessor);
    }
  });
  return searchHeaders;
};

export const getHeaders = (headers) => headers.map((item) => item.accessor);
